import {
  useState,
  useContext,
  useCallback,
  createContext,
  useEffect,
} from "react";
import { listNotifications } from "../graphql/queries";

import { updateNotification, deleteNotification } from "../graphql/mutations";

import { API, graphqlOperation } from "aws-amplify";
import { AmplifyAuthContext } from "./AmplifyAuthContext";

export const NotificationsContext = createContext<{
  notifications: any[];
  readNotification: (arg0: string) => Promise<any> | void;
  removeNotificationOfUser: any;
  removeNotificationOfTask: any;
}>({
  notifications: [],
  readNotification: (_arg0: string) => {},
  removeNotificationOfTask: () => {},
  removeNotificationOfUser: () => {},
});

export const NotificationsProvider = ({ children }) => {
  const { id } = useContext(AmplifyAuthContext);
  const [notifications, setNotifications] = useState([]);
  const refresh = useCallback(() => {
    (API.graphql(graphqlOperation(listNotifications)) as Promise<any>)
      .then((resp) => {
        setNotifications(
          resp.data.listNotifications.items.sort(compareByCreatedAt)
        );
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    if (id) {
      refresh();
    }
  }, [refresh, id]);

  const removeNotificationOfUser = (userId: string) => {
    let targetNotifications = notifications.filter(
      (notification) => notification.userID === userId
    );
    let promises = targetNotifications.map((notification) => {
      API.graphql(
        graphqlOperation(deleteNotification, { input: { id: notification.id } })
      );
    });
    return Promise.all(promises);
  };
  const removeNotificationOfTask = (taskId: string) => {
    let targetNotifications = notifications.filter(
      (notification) => notification.content === taskId
    );
    let promises = targetNotifications.map((notification) => {
      API.graphql(
        graphqlOperation(deleteNotification, { input: { id: notification.id } })
      );
    });
    return Promise.all(promises);
  };

  const readNotification = (id: string) => {
    const readPromise = (resolve, reject) => {
      (
        API.graphql(
          graphqlOperation(updateNotification, {
            input: { id: id, read: true },
          })
        ) as Promise<any>
      )
        .then((resp) => {
          refresh();
          resolve(resp.data.updateNotification);
        })
        .catch((e) => {
          reject(e);
        });
    };

    return new Promise(readPromise);
  };

  function compareByCreatedAt(a, b) {
    let a_time = new Date(a.createdAt);
    let b_time = new Date(b.createdAt);
    return b_time.getTime() - a_time.getTime();
  }

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        readNotification,
        removeNotificationOfTask,
        removeNotificationOfUser,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
