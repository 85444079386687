import { createContext, useState, useEffect, useContext } from "react";
import { listUsers, getUser } from "../graphql/queries";
import {
  updateUser,
  createUser,
  deleteUser,
  deleteUserTask,
} from "../graphql/mutations";
import { Auth, API, Storage, graphqlOperation } from "aws-amplify";
import { AmplifyAuthContext } from "./AmplifyAuthContext";
import { TasksContext } from "./TasksContext";

export interface IUser {
  id: string;
  displayname: string;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  type: string;
  phoneNumber: string;
  image?: string;
}

export const UsersContext = createContext<any>({
  users: [],
  images: {},
  refresh: () => {},
  addUser: () => {},
  editUser: () => {},
  removeUser: () => {},
  retrieveUser: () => {},
  getUsername: () => {},
  getDisplayName: () => {},
  getID: (username: string) => {
    return username;
  },
  addToGroup: () => {},
  removeUserfromGroup: () => {},
});

export const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [images, setImages] = useState({});
  const [idUsername, setIdUsername] = useState({});
  const [displayName, setDisplayName] = useState({});
  const { id } = useContext(AmplifyAuthContext);
  const refresh = () => {
    (API.graphql(graphqlOperation(listUsers)) as Promise<any>).then((resp) => {
      let usersList = resp.data.listUsers.items;
      setUsers(usersList);

      let tmp = {};
      let idTmp = {};
      let dnTmp = {};
      for (let index in usersList) {
        let item = usersList[index];
        idTmp[item.id] = item.username;
        idTmp[item.username] = item.id;
        dnTmp[item.username] = item.displayname;
        dnTmp[item.id] = item.displayname;
        if (item.image !== null) {
          Storage.get(item.image).then((resp) => {
            tmp[item.username] = resp;
            tmp[item.id] = resp;
          });
        } else {
          tmp[item.username] = "https://i.imgur.com/WCfimk2.jpg";
        }
      }
      setIdUsername(idTmp);
      setDisplayName(dnTmp);
      setImages(tmp);
    });
  };

  const getUsername = (id) => {
    return idUsername[id];
  };

  const getID = (username: string) => {
    return idUsername[username];
  };

  const getDisplayName = (username: string) => {
    return displayName[username];
  };
  const addUser = ({ input, condition }) => {
    const addFunc = (resolve, reject) => {
      (
        API.graphql(
          graphqlOperation(createUser, { input: input, condition: condition })
        ) as Promise<any>
      )
        .then((resp) => {
          refresh();
          resolve(resp.data.createUser);
        })
        .catch((e) => {
          reject(e);
        });
    };
    return new Promise(addFunc);
  };

  const retrieveUser = (id) => {
    const retrieveFunc = (resolve, reject) => {
      (API.graphql(graphqlOperation(getUser, { id: id })) as Promise<any>)
        .then((resp) => {
          resolve(resp.data.getUser);
        })
        .catch((e) => {
          reject(e);
        });
    };
    return new Promise(retrieveFunc);
  };

  const removeUser = (id) => {
    const removeFunc = (resolve, reject) => {
      (
        API.graphql(
          graphqlOperation(deleteUser, { input: { id: id } })
        ) as Promise<any>
      )
        .then((resp) => {
          refresh();
          resolve(resp.data.deleteUser);
        })
        .catch((e) => {
          reject(e);
        });
    };
    return new Promise(removeFunc);
  };

  const editUser = ({ input, condition }) => {
    const editFunc = (resolve, reject) => {
      (
        API.graphql(
          graphqlOperation(updateUser, { input: input, condition: condition })
        ) as Promise<any>
      )
        .then((resp) => {
          refresh();
          resolve(resp.data.updateUser);
        })
        .catch((e) => {
          reject(e);
        });
    };
    return new Promise(editFunc);
  };

  const addToGroup = async (username: string, groupname: string) => {
    let apiName = "AdminQueries";
    let path = "/addUserToGroup";
    let myInit = {
      body: {
        username: username,
        groupname: groupname,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, myInit);
  };

  const removeUserfromGroup = async (username: string, groupname: string) => {
    let apiName = "AdminQueries";
    let path = "/removeUserfromGroup";
    let myInit = {
      body: {
        username: username,
        groupname: groupname,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, myInit);
  };
  // Still needs to update Posts / Comments as it goes on, and there should be refresh i guess
  useEffect(() => {
    if (id) {
      refresh();
    }
  }, [id]);
  return (
    <UsersContext.Provider
      value={{
        users: users,
        images: images,
        refresh: refresh,
        addUser: addUser,
        editUser: editUser,
        retrieveUser: retrieveUser,
        removeUser: removeUser,
        getUsername: getUsername,
        getDisplayName: getDisplayName,
        getID: getID,
        addToGroup: addToGroup,
        removeUserfromGroup: removeUserfromGroup,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
