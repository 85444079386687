import { useEffect } from "react";
import ja from "date-fns/locale/ja";
import DateFnUtils from "@date-io/date-fns"; // choose your lib
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Icon } from "@material-ui/core";

export interface SelectItem {
  label: string;
  value: string;
}

export const initialSelectItem: SelectItem = {
  label: "",
  value: "",
};

export const MuiDatePicker = (props) => {
  const { value, setter } = props;
  useEffect(() => {
    setter(new Date(value.getTime() - (value.getTime() % (15 * 60 * 1000))));
  }, []);

  return (
    <>
      <div className="p-1 h-8">
        <Icon className="float-left">schedule</Icon>
        <p className="pl-1 float-left">{props.title}</p>
      </div>
      <MuiPickersUtilsProvider utils={DateFnUtils} locale={ja}>
        <DatePicker format="yyyy年MM月dd日" value={value} onChange={setter} />
        <span className="px-2" />
        <TimePicker
          ampm={true}
          format="HH:mm"
          value={value}
          onChange={setter}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};
