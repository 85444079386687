import { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { AmplifyAuthContext } from "../contexts/AmplifyAuthContext";
import { SNSContext } from "../contexts/SNSContext";
import { TasksContext } from "../contexts/TasksContext";
import { UsersContext } from "../contexts/UsersContext";
import { Alert } from "@material-ui/lab";
import { errorMessages } from "../constants/ErrorMessages";
import { CircularProgress } from "@material-ui/core";

export const LoginPage = (props) => {
  // TODO: Will be changed to login function from auth context in the later branches
  const { login, authenticated } = useContext(AmplifyAuthContext);
  const { refresh: snsRefresh } = useContext(SNSContext);
  const { refresh: taskRefresh } = useContext(TasksContext);
  const { refresh: userRefresh } = useContext(UsersContext);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState(errorMessages.default);
  const history = useHistory();
  const handleLoginPress = async (e) => {
    e.preventDefault();
    setLoading(true);
    login({
      username: e.target.username.value,
      password: e.target.password.value,
    })
      .then(() => {
        setFailed(false);
        setLoading(false);
        snsRefresh();
        taskRefresh();
        userRefresh();
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err.code);
        if (err.code === "UserNotConfirmedException") {
          alert(
            "ユーザー認証が完了していません。メールに届いたコードを入力してください。"
          );
          history.push("/login/confirm");
        } else if (err.code === "PasswordResetRequiredException") {
          alert(
            "パスワードの再設定が必要です。メールに届いたコードを入力してください。"
          );
        }
        setFailed(true);
        if (errorMessages[err.code] === undefined) {
          setErrorMessage(errorMessages["default"]);
        } else {
          setErrorMessage(errorMessages[err.code]);
        }
      });
  };
  if (authenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="login-screen flex-center">
      {failed && <Alert severity="error">{errorMessage}</Alert>}
      {loading && (
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            backgroundColor: "#FFFFFF77",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <form onSubmit={handleLoginPress}>
        <div className="login-screen__field">
          <label>ユーザー名</label>
          <input name="username" />
        </div>
        <div className="login-screen__field">
          <label>パスワード</label>
          <input name="password" type="password" />
        </div>
        <button className="primary big-button w-full" type="submit">
          LOGIN
        </button>
      </form>
    </div>
  );
};
