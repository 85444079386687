import { useContext } from "react";
import { Icon } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CORPORATE_COLOR } from "../Colors";
import { UIContext } from "../contexts/UIContext";

const MenuItem = ({ item }) => {
  const { setShowHamburger } = useContext(UIContext);
  const history = useHistory();
  const handleClick = () => {
    if (item.link === "") {
      setShowHamburger(false);
    } else {
      setShowHamburger(false);
      history.push(item.link);
    }
  };
  return (
    <div onClick={handleClick} className="hamburger-menu__item">
      <Icon>{item.icon}</Icon>
      <div>{item.name}</div>
    </div>
  );
};

export const HamburgerMenu = (props) => {
  const { items } = props;
  const { showHamburger, setShowHamburger } = useContext(UIContext);

  return (
    <div>
      {showHamburger && (
        <>
          <div
            className="hamburger-menu"
            onClick={(e) => setShowHamburger(false)}
          />
          <div
            style={{ height: "100vh", backgroundColor: CORPORATE_COLOR }}
            className="hamburger-menu__items"
          >
            {items.map((item, idx) => {
              return <MenuItem item={item} key={idx} />;
            })}
          </div>
        </>
      )}
    </div>
  );
};
