import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "@material-ui/core";
import { UIContext, IUIContext, MenuItem } from "../contexts/UIContext";
import { AmplifyAuthContext } from "../contexts";

const DashboardItem = ({ item }: { item: MenuItem }) => {
  const { icon, name, link } = item;
  const history = useHistory();

  return (
    <div
      className="dashboard-item"
      onClick={item.onClick ? item.onClick : () => history.push(link)}
    >
      <Icon style={{ fontSize: 50 }} className="dashboard-item__icon">
        {icon}
      </Icon>
      <p>{name}</p>
    </div>
  );
};

export const DashboardPage = () => {
  const { items } = useContext<IUIContext>(UIContext);
  const LogoutModal = () => {
    const { logout, setShowLogoutModal, showLogoutModal } =
      useContext(AmplifyAuthContext);
    return (
      <div
        className="modal--bg"
        style={{
          display: showLogoutModal ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="modal--fg">
          <div>本当にログアウトしますか？</div>
          <button
            onClick={() => {
              logout();
              setShowLogoutModal(false);
            }}
            className="primary big-button"
          >
            はい
          </button>
          <button
            className="secondary big-button"
            onClick={() => setShowLogoutModal(false)}
          >
            いいえ
          </button>
        </div>
      </div>
    );
  };
  return (
    <>
      <LogoutModal />
      <div className="flex-row flex-wrap w-full justify-around">
        {items.map((item: MenuItem, idx: number) => (
          <DashboardItem item={item} key={idx} />
        ))}
      </div>
    </>
  );
};
