import { useContext } from "react";
import { useParams } from "react-router-dom";
import { SNSPost, CommentMolecule, CommentFooter } from "./SNSPost";
import { SNSContext } from "../../contexts/SNSContext";

export const SNSPostPage = () => {
  const { comments, posts } = useContext(SNSContext);
  let { id } = useParams<{ id: string }>();
  let post = posts.find((element) => element.id === id);
  const compareFn = (a: { createdAt: Date }, b: { createdAt: Date }) =>
    new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  let id_comments = comments
    .filter((element) => element.postID === id)
    .sort(compareFn);
  return (
    <div style={{ marginBottom: 32, paddingBottom: 20, paddingTop: 20 }}>
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <SNSPost value={post} full={true} />
      </div>
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <div style={{ height: 14 }}></div>
        {id_comments.map((content, idx) => {
          return <CommentMolecule key={idx} content={content} />;
        })}
      </div>
      <CommentFooter show={true} value={post} />
    </div>
  );
};
