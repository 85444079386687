/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      displayname
      firstname
      lastname
      phoneNumber
      email
      type
      image
      tasks {
        items {
          id
          taskID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          userID
          type
          content
          read
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        displayname
        firstname
        lastname
        phoneNumber
        email
        type
        image
        tasks {
          nextToken
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userID
      user {
        id
        username
        displayname
        firstname
        lastname
        phoneNumber
        email
        type
        image
        tasks {
          nextToken
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
      type
      content
      read
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        user {
          id
          username
          displayname
          firstname
          lastname
          phoneNumber
          email
          type
          image
          createdAt
          updatedAt
        }
        type
        content
        read
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      name
      description
      memo
      location_name
      location_url
      status
      timeslots
      leaderID
      leader {
        id
        username
        displayname
        firstname
        lastname
        phoneNumber
        email
        type
        image
        tasks {
          nextToken
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
      assigned {
        items {
          id
          taskID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      images
      type
      createdAt
      updatedAt
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        memo
        location_name
        location_url
        status
        timeslots
        leaderID
        leader {
          id
          username
          displayname
          firstname
          lastname
          phoneNumber
          email
          type
          image
          createdAt
          updatedAt
        }
        assigned {
          nextToken
        }
        images
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      content
      comments {
        items {
          id
          content
          postID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      images
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        comments {
          nextToken
        }
        images
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      content
      postID
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        postID
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userTaskByTask = /* GraphQL */ `
  query UserTaskByTask(
    $taskID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTaskByTask(
      taskID: $taskID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskID
        userID
        task {
          id
          name
          description
          memo
          location_name
          location_url
          status
          timeslots
          leaderID
          images
          type
          createdAt
          updatedAt
        }
        user {
          id
          username
          displayname
          firstname
          lastname
          phoneNumber
          email
          type
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userTaskByUser = /* GraphQL */ `
  query UserTaskByUser(
    $userID: ID
    $taskID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTaskByUser(
      userID: $userID
      taskID: $taskID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskID
        userID
        task {
          id
          name
          description
          memo
          location_name
          location_url
          status
          timeslots
          leaderID
          images
          type
          createdAt
          updatedAt
        }
        user {
          id
          username
          displayname
          firstname
          lastname
          phoneNumber
          email
          type
          image
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
