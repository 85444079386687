import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Storage } from "aws-amplify";
import { Icon } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { format } from "date-fns";

import { localeJp } from "../../constants/Taskvalues";
import { AmplifyAuthContext, UsersContext, TasksContext } from "../../contexts";
import { useHistory } from "react-router-dom";

export const TaskPage = () => {
  const history = useHistory();
  const { retrieveTask } = useContext(TasksContext);
  const { getDisplayName } = useContext(UsersContext);
  const { adminGroup } = useContext(AmplifyAuthContext);
  const { id: task_id } = useParams<{ id: string }>();
  const [assigned, setAssigned] = useState<any[]>([]);
  const [task, setTask] = useState<any>({});
  const [currentCursor, setCurrentCursor] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [startEnd, setStartEnd] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [images, setImages] = useState<any[]>([]);
  useEffect(() => {
    retrieveTask(task_id).then((resp) => {
      let imgs = JSON.parse(resp.images);
      let imgPromises = imgs.map((img: string) => Storage.get(img));
      Promise.all(imgPromises).then((images) => setImages(images));
      setTask(resp);
      setAssigned(resp.assigned.items.map((user) => user.userID));
      setStartEnd(getTimeslot(resp));
      setLoading(false);
    });
  }, []);

  const getTimeslot = (task: { timeslots: string }) => {
    try {
      let timeslots = JSON.parse(task?.timeslots);
      return {
        start: new Date(timeslots[0].startAt),
        end: new Date(timeslots[0].endAt),
      };
    } catch (e) {
      return startEnd;
    }
  };

  return (
    <div className="w-full">
      <div className="task--detail flex-col flex-center">
        {!loading ? (
          <>
            <div className="task--detail__title flex-row flex-center">
              <div
                style={{ margin: 10 }}
                className={`circle bg--${
                  task?.type?.toLowerCase() || "default"
                }`}
              />
              <div className="location-wrapper">
                <h1>{task?.name}</h1>
                <h3>{localeJp[task?.type]}</h3>
              </div>
            </div>
            <div className="task--detail__location flex-row">
              <Icon style={{ margin: 8 }}>location_on</Icon>
              <div>
                <a className="text-white" href={task?.location_url}>
                  {task?.location_name}
                </a>
              </div>
            </div>
            <div className="task--detail__date flex-row">
              <Icon style={{ margin: 8 }}>today</Icon>
              <div style={{ width: 50, marginLeft: 4 }} className="flex-column">
                <div>START</div>
                <div>END</div>
              </div>
              <div className="flex-column" style={{ marginLeft: 16 }}>
                <div>{`${format(startEnd.start, "yyyy/MM/dd")}`}</div>
                <div>{`${format(startEnd.end, "yyyy/MM/dd")}`}</div>
              </div>
            </div>
            <div className="task--detail__time flex-row">
              <Icon style={{ margin: 8 }}>watch_later</Icon>
              <div style={{ width: 50, marginLeft: 4 }} className="flex-column">
                <span>IN</span>
                <span>OUT</span>
              </div>
              <div className="flex-column" style={{ marginLeft: 16 }}>
                <span>
                  {`${startEnd.start.getHours()}`.padStart(2, "0")}:
                  {`${startEnd.start.getMinutes()}`.padStart(2, "0")}
                </span>
                <span>
                  {`${startEnd.end.getHours()}`.padStart(2, "0")}:
                  {`${startEnd.end.getMinutes()}`.padStart(2, "0")}
                </span>
              </div>
            </div>
            <div className="task--detail__leader">
              <h2>【責任者】</h2>
              <ul className="flex-row">
                <li
                  className={`nametag bg--${
                    task?.type?.toLowerCase() || "default"
                  }`}
                >
                  {task?.leader?.displayname}
                </li>
              </ul>
            </div>
            {assigned.length > 0 && (
              <div className="task--detail__member">
                <h2>【同行者】</h2>
                <ul className="flex-row">
                  {assigned.map((assigned) => (
                    <li
                      key={assigned.id}
                      className={`nametag bg--${
                        task?.type?.toLowerCase() || "default"
                      }`}
                    >
                      {getDisplayName(assigned)}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="task--detail__description">
              <h2>【詳細】</h2>
              <textarea
                readOnly
                defaultValue={`詳細: ${task?.description}\nメモ: ${task?.memo}`}
              />
            </div>
            {images.length > 0 && (
              <div className="task--detail__images">
                <h2>【写真】</h2>
                <div className="flex-row carousel">
                  <button
                    onClick={() =>
                      setCurrentCursor((currentCursor - 1) % images.length)
                    }
                  >
                    <Icon>chevron_left</Icon>
                  </button>
                  {[0, 1, 2].map((idx: number) => (
                    <img
                      src={images[(currentCursor + idx) % images.length]}
                      alt={`${(currentCursor + idx) % images.length}`}
                    />
                  ))}
                  <button
                    onClick={() =>
                      setCurrentCursor((currentCursor + 1) % images.length)
                    }
                  >
                    <Icon>chevron_right</Icon>
                  </button>
                </div>
              </div>
            )}
            <div className="task--detail__button w-full flex-row flex-center">
              {adminGroup && (
                <button
                  className="secondary big-button"
                  onClick={() => history.push(`/tasks/edit/${task_id}`)}
                >
                  編集
                </button>
              )}
              <button
                className="secondary big-button"
                onClick={() => history.goBack()}
              >
                戻る
              </button>
            </div>
          </>
        ) : (
          <div className="flex-row flex-center h-full">
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};
