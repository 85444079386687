import { useState, useContext, useEffect } from "react";
import { NotificationsContext } from "../contexts/NotificationsContext";
import { AmplifyAuthContext } from "../contexts/AmplifyAuthContext";
import { Notification } from "../organisms/Notification";

export const NotificationsPage = () => {
  const { notifications } = useContext(NotificationsContext);
  const { id } = useContext(AmplifyAuthContext);
  const [toShow, setToShow] = useState<any[]>([]);

  useEffect(() => {
    setToShow(notifications.map((notification) => notification.userID === id));
  }, [id, notifications]);
  return (
    <>
      {toShow.length < 1 ? (
        <>通知なし</>
      ) : (
        <>
          {toShow.map((notification) => {
            return <Notification item={notification} />;
          })}
        </>
      )}
    </>
  );
};
