export const CORPORATE_COLOR = "#143057";
export const STATUS_COLOR = {
  WORKING: {
    backgroundColor: "#34a8eb",
    color: "#194f6e",
  },
  IDLING: {
    backgroundColor: "#d0d7db",
    color: "#757575",
  },
  DONE: {
    backgroundColor: "#7cd991",
    color: "#3c824b",
  },
};

export const Theme = {
  PRIMARY: {
    backgroundColor: "",
    color: "",
  },
  SECONDARY: {
    backgroundColor: "",
    color: "",
  },
  TERTIARY: {
    backgroundColor: "",
    color: "",
  },
};

export const TYPE_COLOR = {
  HOUSE_CONSTRUCTION: {
    backgroundColor: "#55D4A8",
    color: "white",
  },
  HOUSE_DESTRUCTION: {
    backgroundColor: "#9BEB6A",
    color: "white",
  },
  LARGE_PROPERTY_CONSTRUCTION: {
    backgroundColor: "#D4BE55",
    color: "white",
  },
  LARGE_PROPERTY_DESTRUCTION: {
    backgroundColor: "#F6A462",
    color: "white",
  },
  DEFAULT: {
    backgroundColor: "#143507",
    color: "white",
  },
};
