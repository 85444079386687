import { useState, useContext } from "react";
import { NotificationsContext } from "../contexts/NotificationsContext";
import { TasksContext } from "../contexts/TasksContext";
import { useHistory } from "react-router-dom";
import { Message } from "../constants/NotificationMessages";
import { format } from "date-fns";

export const Notification = ({ item }) => {
  console.log(item);
  const { id, type, content, read, createdAt } = item;
  const { readNotification } = useContext(NotificationsContext);
  const [localRead, setLocalRead] = useState(read);
  const link = type === "TASK" ? `/task/${content}` : `/sns/post/${content}`;

  let className = "w-full p-2 border-t ";
  if (!localRead) {
    className += "bg-blue-100";
  }

  const history = useHistory();
  const { getName } = useContext(TasksContext);

  const handleClick = () => {
    setLocalRead(true);
    readNotification(id);
    history.push(link);
  };

  // This would include the text and if the notification is seen or not
  // Notification includes type, content and the whatever id either taskid or post id
  return (
    <div className={className} onClick={handleClick}>
      <div>{Message[type]}</div>
      <div>{getName(content)}</div>
      <div className="text-xs text-gray-400">
        {format(new Date(createdAt), "yyyy-MM-dd HH:mm")}
      </div>
    </div>
  );
};
