import { LoginPage } from "../pages/LoginPage";
import { LogoutPage } from "../pages/LogoutPage";
import { ForgotPasswordPage } from "../pages/ForgotPasswordPage";
import {
  // SNS
  SNSPostPage,
  SNSNewPage,
  SNSListPage,
  CalendarPage,

  // Tasks
  TaskPage,
  TasksCalendarPage,
  NewTaskPage,
  AdminTaskEditPage,

  // Users
  NewUserPage,
  AdminUserEditPage,
  UserPage,
  UserListPage,
  ProfileEditPage,
} from "../pages";
import { NotificationsPage } from "../pages/NotificationsPage";
import { DashboardPage } from "../pages/DashboardPage";
import { ConfirmUserPage } from "../pages/ConfirmUserPage";
import { ResendSignUpPage } from "../pages/ResendSignUp";

export enum Permission {
  UNAUTHORIZED = 0,
  AUTHORIZED = 1,
  ADMIN = 2,
}

export interface IRoute {
  component: any;
  path: string;
  permission: Permission;
}

export const routes: IRoute[] = [
  { component: LoginPage, path: "/login", permission: Permission.UNAUTHORIZED },
  {
    component: LogoutPage,
    path: "/logout",
    permission: Permission.UNAUTHORIZED,
  },
  {
    component: ForgotPasswordPage,
    path: "/forgot_password",
    permission: Permission.UNAUTHORIZED,
  },
  {
    component: SNSPostPage,
    path: "/sns/posts/:id",
    permission: Permission.AUTHORIZED,
  },
  {
    component: SNSNewPage,
    path: "/sns/new",
    permission: Permission.AUTHORIZED,
  },
  {
    component: SNSListPage,
    path: "/sns",
    permission: Permission.AUTHORIZED,
  },
  {
    component: UserListPage,
    path: "/users/edit",
    permission: Permission.ADMIN,
  },
  { component: NewUserPage, path: "/users/new", permission: Permission.ADMIN },
  {
    component: UserPage,
    path: "/users/:user_id",
    permission: Permission.AUTHORIZED,
  },
  {
    component: UserPage,
    path: "/profile",
    permission: Permission.AUTHORIZED,
  },
  {
    component: ProfileEditPage,
    path: "/profile/edit",
    permission: Permission.AUTHORIZED,
  },
  {
    component: CalendarPage,
    path: "/calendar",
    permission: Permission.AUTHORIZED,
  },
  {
    component: NewTaskPage,
    path: "/tasks/new",
    permission: Permission.AUTHORIZED,
  },
  {
    component: NotificationsPage,
    path: "/notifications",
    permission: Permission.AUTHORIZED,
  },
  {
    component: AdminTaskEditPage,
    path: "/tasks/edit/:id",
    permission: Permission.ADMIN,
  },
  {
    component: TaskPage,
    path: "/tasks/:id",
    permission: Permission.AUTHORIZED,
  },
  {
    component: TasksCalendarPage,
    path: "/tasks",
    permission: Permission.AUTHORIZED,
  },
  {
    component: ConfirmUserPage,
    path: "/login/confirm",
    permission: Permission.UNAUTHORIZED,
  },
  {
    component: ResendSignUpPage,
    path: "/login/resend",
    permission: Permission.UNAUTHORIZED,
  },
  {
    component: AdminUserEditPage,
    path: "/users/edit/:id",
    permission: Permission.ADMIN,
  },
  { component: DashboardPage, path: "/", permission: Permission.AUTHORIZED },
];
