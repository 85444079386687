import { createContext, useState } from "react";

export const DateContext = createContext<any>({
  now: 0,
  selected: 0,
  setSelected: () => {},
  moveMonth: () => {},
  dayOfTheWeek: [],
});

export const DateProvider = (props) => {
  const [selected, setSelected] = useState(new Date().getTime());
  const moveMonth = (value) => {
    let date = new Date(selected);
    date.setMonth(date.getMonth() + value);
    setSelected(date.getTime());
  };
  const dayOfTheWeek = ["日", "月", "火", "水", "木", "金", "土"];
  return (
    <DateContext.Provider
      value={{
        now: new Date().getTime(),
        selected: selected,
        setSelected: setSelected,
        moveMonth: moveMonth,
        dayOfTheWeek: dayOfTheWeek,
      }}
    >
      {props.children}
    </DateContext.Provider>
  );
};
