import { useState, useEffect, useContext, createContext } from "react";
import { listTasks, getTask } from "../graphql/queries";
import {
  createTask,
  updateTask,
  deleteTask,
  createUserTask,
  createNotification,
  deleteUserTask,
} from "../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { AmplifyAuthContext } from "./AmplifyAuthContext";

export interface Task {
  id: string;
  name: string;
  description: string;
  leaderID: string;
  memo: string;
  location_name: string;
  location_url: string;
  timeslots: string;
  images: string[];
  status: string;
  type: string;
  assigned?: { items: any[] };
  removeUserTasks: any;
}

export const TasksContext = createContext<any>({
  tasks: [],
  withAssigned: {},
  byTimeslot: [],
  refresh: () => {},
  retrieveTask: () => {},
  addTask: () => {},
  queryTasks: () => {},
  addUserTask: () => {},
  removeTask: () => {},
  editTask: () => {},
  removeUserTasks: () => {},
});

export const TasksProvider = ({ children }) => {
  const [tasks, setTasks] = useState<any[]>([]);
  const [tasksMap, setTasksMap] = useState<{ [key: string]: any }>({});
  const [withAssigned, setWithAssigned] = useState<{ [key: string]: any }>({});
  const [byTimeslot, setByTimeslot] = useState<any[]>([]);
  const { id } = useContext(AmplifyAuthContext);
  const getAllTasks = async () => {
    let tmp: Task[] = [];
    let tmpWithAssigned = {};
    let tmpTasksMap = {};
    let { data }: any = await API.graphql(graphqlOperation(listTasks));
    let items: any[] = data.listTasks.items;
    setByTimeslot(separateByTimeslot(items));
    items.forEach((task: Task) => {
      (
        API.graphql(graphqlOperation(getTask, { id: task.id })) as Promise<any>
      ).then((resp: any) => {
        tmpTasksMap[task.id] = task;

        tmpWithAssigned[task.id] = resp.data.getTask.assigned.items.map(
          (usertask) => usertask.userID
        );
        tmp.push(resp.data.getTask);
      });
    });
    setWithAssigned(tmpWithAssigned);
    setTasks(tmp);
    setTasksMap(tmpTasksMap);
  };
  const refresh = () => {
    getAllTasks();
  };

  const queryTasks = (filter: any) => {
    (
      API.graphql(
        graphqlOperation(listTasks, { input: { filter: filter } })
      ) as Promise<any>
    ).then((res) => console.log(res.data.listTasks.items));
  };

  const separateByTimeslot = (items: Task[]) => {
    let tmp: any[] = [];
    items.forEach((task: any) => {
      let timeslotsJSON = JSON.parse(task.timeslots);

      Object.keys(timeslotsJSON).forEach((element: any) => {
        let startTimeslot = new Date(timeslotsJSON[element].startAt);
        let endTimeslot = new Date(timeslotsJSON[element].endAt);

        tmp.push({
          id: task.id,
          status: task.status,
          title: task.name,
          start: startTimeslot,
          type: task.type,
          end: endTimeslot,
          leadername: task.leader.displayname,
          leaderID: task.leaderID,
        });
      });
    });
    return tmp;
  };

  useEffect(() => {
    if (id) {
      refresh();
    }
  }, [id]);

  const getName = (id: string) => {
    return tasksMap[id].name || "";
  };

  const addTask = ({ input, condition }) => {
    const addFunc = (resolve, reject) => {
      (
        API.graphql(
          graphqlOperation(createTask, { input: input, condition: condition })
        ) as Promise<any>
      )
        .then((resp) => {
          console.log(resp.data.createTask);
          let task = resp.data.createTask;
          console.log(task);
          API.graphql(
            graphqlOperation(createNotification, {
              input: {
                userID: task.leaderID,
                type: "TASK",
                content: task.id,
                read: false,
              },
            })
          );
          refresh();
          resolve(resp.data.createTask);
        })
        .catch((e) => {
          reject(e);
        });
    };
    return new Promise(addFunc);
  };

  const addUserTask = ({ input, condition }) => {
    const addFunc = (resolve, reject) => {
      (
        API.graphql(
          graphqlOperation(createUserTask, {
            input: input,
            condition: condition,
          })
        ) as Promise<any>
      )
        .then((resp) => {
          refresh();
          let usertask = resp.data.createUserTask;
          API.graphql(
            graphqlOperation(createNotification, {
              input: {
                userID: usertask.userID,
                type: "TASK",
                content: usertask.taskID,
                read: false,
              },
            })
          );
          resolve(resp.data.createUserTask);
        })
        .catch((e) => {
          reject(e);
        });
    };
    return new Promise(addFunc);
  };

  const removeUserTasks = (userId) => {
    let allUserTasks = tasks.map((task) => task.assigned.items).flat();
    let targetUserTasks = allUserTasks.filter((item) => item.userID === userId);
    console.log(targetUserTasks);
    let promises = targetUserTasks.map(
      (userTask) =>
        API.graphql(
          graphqlOperation(deleteUserTask, {
            input: { id: userTask.id },
          })
        ) as Promise<any>
    );
    return Promise.all(promises);
  };

  const removeTask = (id: string) => {
    const removeFunc = (resolve, reject) => {
      let targetTask = tasks.find((item) => item.id === id);
      console.log(targetTask);
      console.log(targetTask.assigned.items);
      let promises = targetTask.assigned.items.map(
        (item: any) =>
          API.graphql(
            graphqlOperation(deleteUserTask, { input: { id: item.id } })
          ) as Promise<any>
      );
      Promise.all(promises).then(() => {
        (
          API.graphql(
            graphqlOperation(deleteTask, {
              input: { id: id },
            })
          ) as Promise<any>
        )
          .then((resp) => {
            refresh();
            resolve(resp.data.deleteTask);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    };

    return new Promise(removeFunc);
  };

  const retrieveTask = async (id: string) => {
    let resp: any = await API.graphql(graphqlOperation(getTask, { id: id }));
    return resp.data.getTask;
  };

  const editTask = ({ input, condition }) => {
    const editFunc = (resolve, reject) => {
      (
        API.graphql(
          graphqlOperation(updateTask, { input: input, condition: condition })
        ) as Promise<any>
      )
        .then((resp) => {
          refresh();
          resolve(resp.data.updateTask);
        })
        .catch((e) => {
          reject(e);
        });
    };
    return new Promise(editFunc);
  };

  return (
    <TasksContext.Provider
      value={{
        tasks,
        tasksMap,
        withAssigned,
        byTimeslot,
        refresh,
        addTask,
        editTask,
        retrieveTask,
        removeTask,
        addUserTask,
        queryTasks,
        getName,
        removeUserTasks,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};
