import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { Icon } from "@material-ui/core";
import {
  addDays,
  getWeeksInMonth,
  addMonths,
  addWeeks,
  format,
  isSameMonth,
  isSameDay,
  startOfWeek,
  startOfMonth,
} from "date-fns";

import { TasksContext, UsersContext } from "../../contexts";

const daysOfTheWeek = ["日", "月", "火", "水", "木", "金", "土"];

export const CalendarPage = () => {
  const history = useHistory();
  const { byTimeslot, withAssigned } = useContext(TasksContext);
  const { getDisplayName } = useContext(UsersContext);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [tasksThisMonth, setTasksThisMonth] = useState<any[]>([]);
  const handleClickTask = (task_id: string) => {
    history.push(`/tasks/${task_id}`);
  };

  const betweenDate = (date: Date, task: any) => {
    return task.start < date && task.end > date;
  };

  useEffect(() => {
    // queryTasks({ filter: { gt: new Date() } });
    let tasks = byTimeslot.filter((item: any) => {
      return (
        isSameMonth(item.start, selectedDate) ||
        isSameMonth(item.end, selectedDate)
      );
    });
    setTasksThisMonth(tasks);
  }, [selectedDate, byTimeslot]);

  const convertToDate = (week: number, day: number) => {
    return addDays(
      startOfWeek(addDays(startOfMonth(selectedDate), week * 7)),
      day
    );
  };
  return (
    <div className="flex-column align-items-center">
      <div className="calendar">
        <div className="calendar__header flex-row w-full justify-between">
          <div className="flex-row">
            <Icon
              className="calendar__header--icon"
              onClick={() => setSelectedDate(new Date())}
            >
              sort
            </Icon>
            <div className="calendar__header--date" style={{ paddingLeft: 14 }}>
              {format(selectedDate, "yyyy年MM月")}
            </div>
          </div>
          <div className="flex-row">
            <button
              onClick={() => setSelectedDate(addMonths(selectedDate, -1))}
            >
              <Icon className="calendar__header--icon">chevron_left</Icon>
            </button>
            <button onClick={() => setSelectedDate(addMonths(selectedDate, 1))}>
              <Icon className="calendar__header--icon">chevron_right</Icon>
            </button>
          </div>
        </div>
        <div className="calendar__body w-full">
          <div>
            <ul className="flex-row justify-around">
              {daysOfTheWeek.map((item, idx: number) => {
                return (
                  <li className="calendar__date" key={idx}>
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <ul className="flex-column justify-around">
              {Array.from(Array(getWeeksInMonth(selectedDate)).keys()).map(
                (week: any, idx: number) => {
                  return (
                    <div
                      className="flex-row justify-around"
                      key={idx}
                      style={{
                        marginBottom: 18,
                      }}
                    >
                      {daysOfTheWeek.map((_item, idx) => {
                        const thisDate = convertToDate(week, idx);
                        return (
                          <div key={idx}>
                            <div
                              className={`flex-row flex-center calendar__date ${
                                isSameMonth(selectedDate, thisDate)
                                  ? isSameDay(selectedDate, thisDate)
                                    ? "calendar__date--selected"
                                    : "calendar__date--same-month"
                                  : "calendar__date--different-month"
                              }`}
                              onClick={() => setSelectedDate(thisDate)}
                              key={week * 7 + idx}
                            >
                              {`${thisDate.getDate()}`.padStart(2, "0")}
                            </div>
                            <div
                              className="flex-row flex-wrap"
                              style={{ width: 33, paddingTop: 6 }}
                            >
                              {isSameMonth(selectedDate, thisDate) && (
                                <>
                                  {tasksThisMonth.map((task: any) => {
                                    if (betweenDate(thisDate, task)) {
                                      return (
                                        <div
                                          key={task.id}
                                          className={`calendar__project-item bg--${
                                            task?.type?.toLowerCase() ||
                                            "default"
                                          }`}
                                        />
                                      );
                                    } else {
                                      return null;
                                    }
                                  })}
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="flex-column w-full scroll-calendar">
        <div
          className="w-full"
          style={{ paddingTop: 53, position: "relative", width: "100%" }}
        >
          <div
            className="w-full flex-row"
            style={{
              position: "absolute",
              top: 13,
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                width: 64,
                backgroundColor: "white",
                height: 4,
              }}
            />
          </div>
          {daysOfTheWeek.map((_: string, idx: number) => {
            const tasksOfThisDay = tasksThisMonth.filter((task) =>
              betweenDate(addDays(selectedDate, idx), task)
            );
            return (
              <div
                style={{
                  color: "white",
                  paddingLeft: 32,
                  paddingRight: 32,
                }}
              >
                <div style={{ textAlign: "left", fontWeight: 600 }}>
                  {format(addDays(selectedDate, idx), "iiii dd").toUpperCase()}
                </div>
                {tasksOfThisDay.map((task) => {
                  let thisAssigned = withAssigned[task.id] || [];
                  return (
                    <div
                      key={task.id}
                      className="scroll-calendar__wrapper flex-row"
                      style={{
                        marginBottom: 16,
                        height: 50,
                      }}
                    >
                      <div
                        className="scroll-calendar__time"
                        style={{ paddingRight: 23 }}
                      >
                        <div>{format(task.start, "HH:mm")}</div>
                        <div>{format(task.end, "HH:mm")}</div>
                      </div>
                      <div
                        className={`scroll-calendar__group bg--${
                          task?.type?.toLowerCase() || "default"
                        }`}
                        style={{
                          marginTop: 4,
                          marginRight: 12,
                        }}
                      />
                      <div
                        className="scroll-calendar__task"
                        onClick={() => handleClickTask(task.id)}
                      >
                        <div className="scroll-calendar__title">
                          {task.title}
                        </div>
                        <div>
                          <ul className="scroll-calendar__names">
                            <li>
                              <strong>{task.leadername}</strong>
                              {thisAssigned.length ? "・" : ""}
                            </li>
                            {thisAssigned.map((id: string, idx: number) => (
                              <li key={idx}>
                                {getDisplayName(id)}
                                {idx !== thisAssigned.length - 1 && "・"}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <hr style={{ width: "98vw" }} />
        <div
          className="scroll-calendar__buttons"
          style={{ marginBottom: 37, marginTop: 47 }}
        >
          <div
            className="scroll-calendar__button"
            onClick={() => setSelectedDate(addWeeks(selectedDate, -1))}
          >
            {"＜前の週へ"}
          </div>
          <div
            className="scroll-calendar__button"
            onClick={() => setSelectedDate(addWeeks(selectedDate, 1))}
          >
            {"次の週へ＞"}
          </div>
        </div>
        <div>
          <button
            className="big-button secondary"
            onClick={() => history.push("/")}
          >
            戻る
          </button>
        </div>
      </div>
    </div>
  );
};
