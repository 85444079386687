import { CORPORATE_COLOR } from "../Colors";
const Button = (props: any) => {
  const buttonStyle = {
    backgroundColor: CORPORATE_COLOR,
    color: "white",
    width: "100%",
    borderRadius: 11,
    fontSize: 17,
    fontWeight: 600,
    paddingTop: 15,
    paddingBottom: 15,
    textAlign: "center",
    ...props.style,
  };
  return (
    <button style={buttonStyle} {...props}>
      {props.children || props.text || "Button"}
    </button>
  );
};

export default Button;
