import { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { UsersContext } from "../../contexts/UsersContext";
import { Auth } from "aws-amplify";
import toast from "react-hot-toast";

export const NewUserPage = () => {
  const history = useHistory();
  const [nameOnly, setNameOnly] = useState<boolean>(false);
  const { addUser } = useContext(UsersContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async ({
    firstName,
    lastName,
    email,
    phoneNumber,
    username,
  }) => {
    console.error(errors);
    // Check form
    // Gives back error if there are any client side error
    // prepare all the necessary values
    // Send reuqest
    // Feedback
    if (errors) {
      try {
        username = nameOnly ? username : email.split("@")[0];
      } catch (e) {
        console.error(e);
        toast.error("有効な値を入力してください。");
        return;
      }
      try {
        let resp = await Auth.signUp({
          attributes: {
            given_name: firstName,
            family_name: lastName,
            email: nameOnly ? null : email,
          },
          username: username,
          password: "password",
        });
        await addUser({
          input: {
            id: resp.userSub,
            displayname: username,
            username: username,
            firstname: firstName,
            lastname: lastName,
            phoneNumber: phoneNumber ? "+81" + phoneNumber : null,
            email: nameOnly ? "" : email,
            type: "WORKER",
          },
        });
        toast.success("ユーザー作成が完了しました。");
        history.push("/");
      } catch (e) {
        console.error(e);
        toast.error("ユーザー作成に失敗しました。");
      }
    }
  };
  return (
    <div className="flex-column flex-center w-full">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.error(errors);
          console.log(e);
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="input-field flex-column ">
          <label className="input-field">
            姓<span style={{ color: "red" }}>*</span>
          </label>
          <input
            {...register("lastName", {
              required: { value: true, message: "*必須項目です。" },
            })}
          />
          <p className="errorMessage">{errors.lastName?.message}</p>
        </div>
        <div className="input-field flex-column ">
          <label className="input-field">
            名<span style={{ color: "red" }}>*</span>
          </label>
          <input
            {...register("firstName", {
              required: { value: true, message: "*必須項目です。" },
            })}
          />
          <p className="errorMessage">{errors.firstName?.message}</p>
        </div>
        {nameOnly ? (
          <div className="input-field flex-column ">
            <label className="input-field">
              ユーザー名
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              {...register("username", {
                required: {
                  value: nameOnly,
                  message:
                    "*ユーザー名、もしくはメールアドレスを入力してください。",
                },
                pattern: /[a-zA-Z0-9-_.]+$/,
              })}
            />
            <p className="errorMessage">{errors.username?.message}</p>
          </div>
        ) : (
          <div className="input-field flex-column ">
            <label className="input-field">メールアドレス</label>
            <input
              {...register("email", {
                required: {
                  value: !nameOnly,
                  message:
                    "*ユーザー名、もしくはメールアドレスを入力してください。",
                },
                pattern: {
                  value: /[a-zA-Z0-9-_.+]+@[a-zA-Z0-9-_.]+$/,
                  message: "無効なメールアドレスです。",
                },
              })}
            />
            <p className="errorMessage">{errors.email?.message}</p>
          </div>
        )}
        <div className="input-field flex-column ">
          <label className="input-field">電話番号</label>
          <input
            {...register("phoneNumber", {
              min: 10,
              max: 11,
            })}
          />
          <p>{errors.phoneNumber?.message}</p>
        </div>
        <div className="flex-row checkbox-container w-full">
          <input
            className="checkbox-container__checkbox"
            type="checkbox"
            checked={nameOnly}
            onChange={() => setNameOnly(!nameOnly)}
          />
          <div className="checkbox-container__label">
            ※名前だけ登録の場合は、こちらにチェックを
          </div>
        </div>
        <div className="w-full flex-column flex-center">
          <button
            className="primary big-button"
            style={{ marginTop: 24, marginBottom: 24 }}
            type="submit"
          >
            登録する
          </button>
          <button
            className="primary big-button"
            onClick={() => history.goBack()}
          >
            戻る
          </button>
        </div>
      </form>
    </div>
  );
};
