import { useState, useContext, useRef } from "react";
import { AmplifyAuthContext, UsersContext } from "../../contexts";
import { Storage } from "aws-amplify";
import { IUser } from "../../contexts/UsersContext";
import { Icon } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { resizeFile } from "../../molecules";
import { Images as ImagePrefixes } from "../../constants/Prefixes";

export const ProfileEditPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [changes, setChanges] = useState<Partial<IUser>>({});
  const [newImage, setNewImage] = useState(null);
  const { id, adminGroup } = useContext(AmplifyAuthContext);
  const { users, images, editUser } = useContext(UsersContext);
  const imageRef = useRef(null);
  const editable = location.pathname.includes("profile") || adminGroup;
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const placeholderImage = "https://i.imgur.com/WCfimk2.jpg";

  const targetUser = {
    ...users.filter((user) => user.id === id)[0],
    image: images[id] || placeholderImage,
  };

  const handleClickSave = () => {
    if (submitButtonRef.current) {
      submitButtonRef?.current?.click();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await editUser({
        input: {
          ...changes,
          id: id,
        },
      });
      toast.success("ユーザー情報の変更が完了しました。");
    } catch {
      toast.error("ユーザー情報の編集に失敗しました。");
    }
  };

  const handleFormChange = (e) => {
    setChanges({ ...changes, [e.target.name]: e.target.value });
  };

  const handleClickImage = () => {
    imageRef.current.click();
  };

  const handleImageChange = async (e) => {
    let file = e.target.files[0];
    let image = await resizeFile(file);
    const savePath = ImagePrefixes.profile + file.name;
    Storage.put(savePath, image, {
      contentType: "image/jpg",
    })
      .then((result: any) => {
        Storage.get(result.key)
          .then((result) => {
            setChanges({ ...changes, image: savePath });
            setNewImage(result);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="user-detail flex-column flex-center">
      <div className="user-detail__image">
        <input
          type="file"
          accept="image/jpg, image/png"
          ref={imageRef}
          onChange={handleImageChange}
        />
        <img src={newImage || targetUser.image} onClick={handleClickImage} />
        <Icon>photo_camera</Icon>
      </div>
      <div className="user-detail__fields flex-column">
        <form onSubmit={handleSubmit} onChange={handleFormChange}>
          <div className="user-detail__field">
            <label>表示名</label>
            <input name="displayname" defaultValue={targetUser.displayname} />
            <Icon>edit</Icon>
          </div>
          <div className="user-detail__field">
            <label>姓</label>
            <input name="lastname" defaultValue={targetUser.lastname} />
            <Icon>edit</Icon>
          </div>
          <div className="user-detail__field">
            <label>名</label>
            <input name="firstname" defaultValue={targetUser.firstname} />
            <Icon>edit</Icon>
          </div>
          <div className="user-detail__field">
            <label>メールアドレス</label>
            <input name="email" defaultValue={targetUser.email} />
            <Icon>edit</Icon>
          </div>
          <div className="user-detail__field">
            <label>電話番号</label>
            <input name="phoneNumber" defaultValue={targetUser.phoneNumber} />
            <Icon>edit</Icon>
          </div>
          <button
            type="submit"
            ref={submitButtonRef}
            style={{ display: "none" }}
          ></button>
        </form>
      </div>
      <div className="user-detail__buttons flex-column">
        {editable && (
          <button className="big-button primary" onClick={handleClickSave}>
            保存する
          </button>
        )}
        <div style={{ height: 20 }} />
        <button
          className="big-button secondary"
          onClick={() => history.goBack()}
        >
          戻る
        </button>
      </div>
    </div>
  );
};
