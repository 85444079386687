import { useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  NotificationsContext,
  UsersContext,
  TasksContext,
} from "../../contexts";
import { IUser } from "../../contexts/UsersContext";
import toast from "react-hot-toast";

export const AdminUserEditPage = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { users, editUser, removeUser } = useContext(UsersContext);
  const { removeUserTasks } = useContext(TasksContext);
  const { removeNotificationOfUser } = useContext(NotificationsContext);
  const currentUser = users.filter((user: IUser) => user.id === id)[0];
  const [changes, setChanges] = useState<Partial<IUser>>({});

  const handleInputChange = (key: string) => (value: string) => {
    setChanges({ ...changes, [key]: value });
  };

  const handleDeleteUser = async () => {
    removeNotificationOfUser(id);
    removeUserTasks(id)
      .then(() => {
        removeUser(id)
          .then(() => {
            toast.success("ユーザーの削除が完了しました。");
            history.goBack();
          })
          .catch((e) => {
            console.error(e);
            toast.error("ユーザーの削除に失敗しました。");
          });
      })
      .catch((e) => {
        console.error(e);
        toast.error("ユーザーの削除に失敗しました。");
      });
  };

  const handleEditUser = async () => {
    try {
      await editUser({
        input: {
          ...changes,
          id: id,
        },
      });
      toast.success("ユーザー情報の変更が完了しました。");
      history.goBack();
    } catch (e) {
      console.error(e);
      toast.error("ユーザー情報の編集に失敗しました。");
    }
  };

  return (
    <div className="flex-column flex-center w-full">
      <div className="input-field flex-column ">
        <label className="input-field">姓</label>
        <input
          value={changes?.lastname || currentUser?.lastname}
          onChange={(e) => handleInputChange("lastname")(e.target.value)}
        />
      </div>
      <div className="input-field flex-column ">
        <label className="input-field">名</label>
        <input
          value={changes?.firstname || currentUser?.firstname}
          onChange={(e) => handleInputChange("firstname")(e.target.value)}
        />
      </div>
      <div className="input-field flex-column ">
        <label className="input-field">メールアドレス</label>
        <input
          value={changes?.email || currentUser?.email}
          onChange={(e) => handleInputChange("email")(e.target.value)}
        />
      </div>
      <div className="input-field flex-column ">
        <label className="input-field">電話番号</label>
        <input
          type="number"
          value={changes?.phoneNumber || currentUser?.phoneNumber}
          onChange={(e) => handleInputChange("phoneNumber")(e.target.value)}
        />
      </div>
      <div className="w-full flex-column flex-center">
        <button
          className="primary big-button"
          style={{ marginTop: 24 }}
          onClick={handleEditUser}
        >
          変更する
        </button>
        <button
          className="primary big-button"
          style={{ marginTop: 24, marginBottom: 24 }}
          onClick={handleDeleteUser}
        >
          削除する
        </button>
        <button
          className="secondary big-button"
          onClick={() => history.push("/")}
        >
          戻る
        </button>
      </div>
    </div>
  );
};
