import { useState, createContext, useContext } from "react";
import { Alert } from "@material-ui/lab";
import { Icon } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Select } from "../../organisms/Select";
import { typeList } from "../../constants/Taskvalues";
import { SelectItem, initialSelectItem } from "./components";
import { TasksContext, UsersContext } from "../../contexts";
import { IUser } from "../../contexts/UsersContext";
import { format } from "date-fns";

import { Storage } from "aws-amplify";
import { resizeFile } from "../../molecules";

export const TaskCreateContext = createContext<any>({
  name: "",
  description: "",
  leader: "",
  assigned: [],
  locationName: "",
  locationURL: "",
  images: [],
  memo: [],
  setName: () => {},
  setDescription: () => {},
  setLeader: () => {},
  setLocationName: () => {},
  setLocationURL: () => {},
  setAssigned: () => {},
  setMemo: (_arg0: string) => {},
});

export const NewTaskPage = () => {
  const [taskType, setTaskType] = useState<SelectItem[]>([initialSelectItem]);
  const [leader, setLeader] = useState<SelectItem[]>([initialSelectItem]);
  const [selectedUsers, setSelectedUsers] = useState<SelectItem[]>([]);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [locationName, setLocationName] = useState<string>("");
  const [images, setImages] = useState<any[]>([]);
  const [imagesShow, setImagesShow] = useState<any[]>([]);
  const [memo, setMemo] = useState<string>("");
  const [start, setStart] = useState<Date>(new Date());
  const [end, setEnd] = useState<Date>(new Date());
  const [success, setSuccess] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [_errorMessage, setErrorMessage] =
    useState<string>("エラーが発生しました。");
  const { addTask, addUserTask } = useContext(TasksContext);
  const { users } = useContext(UsersContext);
  const history = useHistory();
  const handleAddTask = () => {
    console.log("handle Add Task");
    let timeslots_obj_json = JSON.stringify({
      0: { startAt: start.getTime(), endAt: end.getTime() },
    });
    let assignedIDs = selectedUsers.map((element: SelectItem) => {
      return element.value;
    });
    let images_json = JSON.stringify(images);
    let leaderID = leader[0].value;
    if (end.getTime() <= start.getTime()) {
      setErrorMessage("時間が有効ではありません。");
      setSuccess(false);
      setHasError(true);
    }
    if (!leaderID) {
      setErrorMessage("責任者を選択してください");
      setSuccess(false);
      setHasError(true);
    }
    console.debug(taskType);
    if (leaderID) {
      addTask({
        input: {
          name: name,
          description: description,
          leaderID: leaderID,
          memo: memo,
          location_name: locationName,
          location_url: "https://google.com/maps",
          timeslots: timeslots_obj_json,
          images: images_json,
          status: "IDLING",
          type: taskType[0].value,
        },
      })
        .then((resp) => {
          assignedIDs.forEach((assignedID) =>
            addUserTask({ input: { userID: assignedID, taskID: resp.id } })
          );
          clearInput();
          setSuccess(true);
          setHasError(false);
          history.push("/tasks");
        })
        .catch((err) => {
          console.error(err);
          setSuccess(false);
          setHasError(true);
        });
    }
  };
  const clearInput = () => {
    setName("");
    setDescription("");
    setLocationName("");
    setMemo("");
    setImages([]);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    // change file.name to something unique
    Storage.put(file.name, image, {
      contentType: "image/jpg",
    })
      .then((result: any) => {
        Storage.get(result.key)
          .then((result) => {
            setImages([...images, file.name]);
            setImagesShow([...imagesShow, result]);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  // const handleDelete = (idx) => {
  //   // let image = images[idx];
  //   // Storage.remove(image.name);
  //   console.log(images);
  //   setImages([...images.slice(0, idx), ...images.slice(idx + 1)]);
  //   setImagesShow([...imagesShow.slice(0, idx), ...imagesShow.slice(idx + 1)]);
  //   console.log(images);
  // };

  return (
    <div className="new-task flex-column flex-center">
      {hasError && <Alert severity="error">エラーが発生しました。</Alert>}
      {success && <Alert severity="success">タスク追加完了！</Alert>}
      <div className="new-task__location flex-column">
        <div className="flex-row align-items-center">
          <Icon>business</Icon>
          <p>現場名</p>
        </div>
        <input value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <div className="new-task__type flex-column">
        <div className="flex-row align-items-center">
          <Icon>house</Icon>
          <p>現場種類を選択</p>
        </div>
        <Select
          width="325px"
          options={[{ label: "選択してください", value: "" }, ...typeList]}
          value={taskType}
          onChange={setTaskType}
        />
      </div>
      <div className="new-task__date">
        <div className="flex-row align-items-center">
          <Icon>date_range</Icon>
          <p>日付を選択する</p>
        </div>
        <div className="flex-row align-items-center justify-between">
          <p>START</p>
          <input
            type="date"
            value={format(start, "yyyy-MM-dd")}
            onChange={(e) => {
              let year = e.target.value.split("-")[0];
              let month = e.target.value.split("-")[1];
              let date = e.target.value.split("-")[2];
              let tmp = new Date(end.getTime());
              tmp.setFullYear(parseInt(year));
              tmp.setMonth(parseInt(month) - 1);
              tmp.setDate(parseInt(date));
              setStart(tmp);
            }}
          />
        </div>
        <div className="flex-row align-items-center justify-between">
          <p>END</p>
          <input
            type="date"
            value={format(end, "yyyy-MM-dd")}
            onChange={(e) => {
              let year = e.target.value.split("-")[0];
              let month = e.target.value.split("-")[1];
              let date = e.target.value.split("-")[2];
              let tmp = new Date(end.getTime());
              tmp.setFullYear(parseInt(year));
              tmp.setMonth(parseInt(month) - 1);
              tmp.setDate(parseInt(date));
              setEnd(tmp);
            }}
          />
        </div>
      </div>
      <div className="new-task__time">
        <div className="flex-row align-items-center">
          <Icon>access_time</Icon>
          <p>時間を選択する</p>
        </div>
        <div className="flex-column">
          <div className="flex-row align-items-center justify-between">
            <p>IN</p>
            <input
              type="time"
              step="900"
              value={format(start, "HH:mm")}
              onChange={(e) => {
                let hours = e.target.value.split(":")[0];
                let minutes = e.target.value.split(":")[1];
                let tmp = new Date(end.getTime());
                tmp.setHours(parseInt(hours));
                tmp.setMinutes(parseInt(minutes));
                setStart(tmp);
              }}
            />
          </div>
          <div className="flex-row align-items-center justify-between">
            <p>OUT</p>
            <input
              type="time"
              step="900"
              value={format(end, "HH:mm")}
              onChange={(e) => {
                let hours = e.target.value.split(":")[0];
                let minutes = e.target.value.split(":")[1];
                let tmp = new Date(end.getTime());
                tmp.setHours(parseInt(hours));
                tmp.setMinutes(parseInt(minutes));
                setEnd(tmp);
              }}
            />
          </div>
        </div>
      </div>
      <div className="new-task__address">
        <div className="flex-row align-items-center">
          <Icon>room</Icon>
          <p>住所</p>
        </div>
        <input
          value={locationName}
          onChange={(e) => setLocationName(e.target.value)}
        />
      </div>
      <div className="new-task__leader">
        <div className="flex-row align-items-center">
          <Icon>person</Icon>
          <p>リーダを選択する</p>
        </div>
        <Select
          width="325px"
          options={[
            { label: "選択してください", value: "" },
            ...users
              .filter((user: IUser) => ["LEADER", "ADMIN"].includes(user.type))
              .map((user: IUser) => ({
                label: user.displayname,
                value: user.id,
              })),
          ]}
          value={leader}
          onChange={setLeader}
        />
      </div>
      <div className="new-task__members">
        <div className="flex-row align-items-center">
          <Icon>group</Icon>
          <p>メンバーを選択する</p>
        </div>
        <Select
          width="325px"
          options={users
            .filter((user: IUser) => user.type === "WORKER")
            .map((user: IUser) => ({
              label: user.displayname,
              value: user.id,
            }))}
          isMulti
          value={selectedUsers}
          onChange={setSelectedUsers}
        />
      </div>
      <div className="new-task__images">
        <input type="file" onChange={handleImageChange} />
        <div>
          {imagesShow.map((image, idx) => (
            <img src={image} alt={`${idx}`} />
          ))}
        </div>
      </div>
      <div className="new-task__memo">
        <div className="flex-row align-items-center">
          <Icon>note</Icon>
          <p>メモ（暗証番号など）</p>
        </div>
        <textarea value={memo} onChange={(e) => setMemo(e.target.value)} />
      </div>
      <div className="flex-column flex-center w-full">
        <button
          className="big-button primary"
          style={{ marginTop: 30, marginBottom: 16 }}
          onClick={handleAddTask}
        >
          登録する
        </button>
        <button
          className="big-button secondary"
          onClick={() => history.push("/")}
        >
          戻る
        </button>
      </div>
    </div>
  );
};
