import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

export const ConfirmUserPage = () => {
  const history = useHistory();
  const confirmUserSignup = (e) => {
    e.preventDefault();
    let username = e.target.username.value;
    let code = e.target.code.value;
    Auth.confirmSignUp(username, code)
      .then((resp) => {
        if (resp === "SUCCESS") {
          history.push("/login");
        } else {
          console.log(resp);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  /*className="p-2 border rounded bg-blue-300 hover:bg-blue-400 text-white"*/
  return (
    <div className="login-screen flex-center">
      <form onSubmit={(e) => confirmUserSignup(e)}>
        <div className="login-screen__field">
          <label>ユーザー名</label>
          <input name="username" />
        </div>
        <div className="login-screen__field">
          <label>確認コード</label>
          <input name="code" />
        </div>
        <button className="primary big-button" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};
