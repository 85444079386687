import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "@material-ui/core";
import { SNSContext, AmplifyAuthContext } from "../../contexts";
import { SNSPost } from "./SNSPost";
import { IPost } from "../../contexts/SNSContext";
import { Button } from "../../atoms";
import { ButtonsModal } from "../../organisms/ButtonsModal";

const ConfirmationModal = ({
  show,
  setShow,
  handleConfirm,
}: {
  show: boolean;
  setShow: (arg0: boolean) => void;
  handleConfirm: any;
}) => {
  return (
    <>
      {show ? (
        <div className="modal--bg">
          <div
            className="modal--fg"
            style={{
              backgroundColor: "white",
              width: "80%",
              position: "absolute",
              top: "20%",
              left: "10%",
              right: "10%",
              borderRadius: 16,
            }}
          >
            <div style={{ height: 20 }} />
            <div>本当に削除しますか?</div>
            <div style={{ height: 20 }} />
            <button
              className="secondary big-button"
              style={{ width: 132 }}
              onClick={handleConfirm}
            >
              はい
            </button>
            <div style={{ height: 20 }} />
            <button
              className="primary big-button"
              style={{ width: 132 }}
              onClick={() => setShow(false)}
            >
              いいえ
            </button>
            <div style={{ height: 20 }} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export const SNSListPage = () => {
  const { posts } = useContext(SNSContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [removeMode, setRemoveMode] = useState<boolean>(false);
  const { id } = useContext(AmplifyAuthContext);
  const [removeIds, setRemoveIds] = useState<string[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const history = useHistory();
  const { removePost } = useContext(SNSContext);
  const handleRemove = () => {
    // TODO Remove multiple posts
    // Maybe make a popup check screen
    let removePromises = removeIds.map((id) =>
      removePost({ input: { id: id } })
    );
    Promise.all(removePromises).then(() => {
      setRemoveMode(false);
    });
  };

  return (
    <div
      className="flex-center"
      style={{ width: "100%", display: "flex", flexDirection: "column" }}
    >
      {/* ------- Modals Start -------*/}
      {!removeMode && (
        <ButtonsModal
          show={showModal}
          setShow={setShowModal}
          handleAdd={() => history.push("/sns/new")}
          handleRemove={() => {
            setRemoveMode(true);
            setShowModal(false);
          }}
        />
      )}

      <ConfirmationModal
        show={showConfirmationModal}
        setShow={setShowConfirmationModal}
        handleConfirm={handleRemove}
      />
      {/* ------- Modals End -------*/}
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            padding: removeMode ? "20px 30px 20px 20px" : 20,
          }}
        >
          {posts.map((value: IPost, idx: number) => {
            return (
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  paddingBottom: 39,
                }}
              >
                <SNSPost
                  onClick={() =>
                    !removeMode && history.push("/sns/posts/" + value.id)
                  }
                  value={value}
                  full={false}
                  key={idx}
                />
                {removeMode && (
                  <input
                    disabled={value?.ownerID === id}
                    style={{ position: "absolute", right: -22 }}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.value) {
                        setRemoveIds([...removeIds, value.id]);
                      } else {
                        setRemoveIds(removeIds.filter((id) => id !== value.id));
                      }
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      {/* -- Float button -- */}
      {!removeMode && (
        <div style={{ position: "fixed", right: 15, bottom: 136 }}>
          <button
            className="float-button bg-orange"
            onClick={() => setShowModal(!showModal)}
          >
            {showModal ? <Icon>close</Icon> : <Icon>add</Icon>}
          </button>
        </div>
      )}

      {removeMode ? (
        <div
          style={{
            position: "absolute",
            width: "100%",
            bottom: 33,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            className="big-button primary"
            style={{ marginBottom: 20 }}
            onClick={() => setShowConfirmationModal(true)}
          >
            削除する
          </button>
          <button
            className="big-button secondary"
            onClick={() => setRemoveMode(false)}
          >
            戻る
          </button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: 242,
            position: "absolute",
            bottom: 31,
          }}
        >
          <Button onClick={() => history.push("/")}>戻る</Button>
        </div>
      )}
    </div>
  );
};
