import { Icon } from "@material-ui/core";

export const ButtonsModal = ({
  show,
  setShow,
  handleAdd,
  handleRemove,
}: {
  show: boolean;
  setShow: (arg0: boolean) => void;
  handleAdd: any;
  handleRemove: any;
}) => {
  return (
    <>
      {show ? (
        <div className="modal--bg">
          <div className="modal--button-container">
            <button
              className="float-button bg-white"
              style={{
                marginBottom: 20,
              }}
              onClick={handleAdd}
            >
              <Icon>add</Icon>
              <p>作成</p>
            </button>
            <button
              onClick={handleRemove}
              className="float-button bg-white"
              style={{
                marginBottom: 20,
              }}
            >
              <Icon>delete</Icon>
              <div>削除</div>
            </button>
            <button
              className="float-button bg-orange"
              onClick={() => setShow(!show)}
            >
              {show ? <Icon>close</Icon> : <Icon>add</Icon>}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
