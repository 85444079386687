import Resizer from "react-image-file-resizer";

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      180,
      180,
      "JPG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });
