import { AmplifyAuthProvider, AmplifyAuthContext } from "./AmplifyAuthContext";
import { DateProvider, DateContext } from "./DateContext";
import { TasksProvider, TasksContext } from "./TasksContext";
import {
  NotificationsProvider,
  NotificationsContext,
} from "./NotificationsContext";
import { SNSProvider, SNSContext } from "./SNSContext";
import { UsersProvider, UsersContext } from "./UsersContext";
import { UIStateProvider, UIContext } from "./UIContext";

export const Providers = ({ children }: { children: JSX.Element }) => {
  return (
    <>
      <AmplifyAuthProvider>
        <UIStateProvider>
          <UsersProvider>
            <DateProvider>
              <SNSProvider>
                <TasksProvider>
                  <NotificationsProvider>{children}</NotificationsProvider>
                </TasksProvider>
              </SNSProvider>
            </DateProvider>
          </UsersProvider>
        </UIStateProvider>
      </AmplifyAuthProvider>
    </>
  );
};

export {
  AmplifyAuthContext,
  DateContext,
  TasksContext,
  NotificationsContext,
  SNSContext,
  UsersContext,
  UIContext,
};
