import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "@material-ui/core";
import { SNSContext, UsersContext } from "../../contexts";
import { IPost } from "../../contexts/SNSContext";
import { format } from "date-fns";

export const CommentMolecule = ({ content }) => {
  const { images, getDisplayName } = useContext(UsersContext);
  let img = images[content.owner];
  const history = useHistory();

  // Apparently owner does not have id here
  return (
    <div style={{ padding: 16, borderBottom: "1px solid lightgray" }}>
      <div className="flex-row w-full">
        <div onClick={() => history.push("/user/" + content.owner)}>
          <img className="sns-post__profile" src={img} alt="prof" />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="sns-post__username">
            {getDisplayName(content.owner)}さん
          </div>
          <div className="sns-post__content">{content.content}</div>
          <div className="sns-post__timestamp">
            {format(new Date(content.createdAt), "yyyy-MM-dd HH:mm")}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CommentFooter = ({
  value,
  show,
}: {
  value: IPost;
  show: boolean;
}) => {
  const { addComment } = useContext(SNSContext);
  const handleComment = (e: any) => {
    e.preventDefault();
    addComment({
      input: { content: e.target.comment.value, postID: value.id },
    })
      .then(() => {
        e.target.reset();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {show && (
        <div className="sns-comment-footer">
          <form
            className="sns-comment-footer__form"
            onSubmit={(e) => handleComment(e)}
          >
            <input
              name="comment"
              className="sns-comment-footer__input"
              placeholder="コメントを入力"
            />
            <button className="sns-comment-footer__button" type="submit">
              <Icon>send</Icon>
            </button>
          </form>
        </div>
      )}
    </>
  );
};

const PostContent = ({
  value,
  onClick = () => {},
}: {
  value: IPost;
  onClick?: (arg0?: any) => void;
}) => {
  let num_comments = value.comments.items.length;
  const { images, getDisplayName } = useContext(UsersContext);
  let img = images[value.owner] || "https://i.imgur.com/WCfimk2.jpg";
  const history = useHistory();
  return (
    <div className="sns-post--wrapper" onClick={onClick}>
      <div onClick={() => history.push("/user/" + value.owner)}>
        <img className="sns-post__profile" src={img} alt="prof" />
      </div>
      <div className="flex-col" style={{ width: "88%", paddingLeft: 9 }}>
        <div className="sns-post__username">
          {getDisplayName(value.owner)}さん
        </div>
        <div className="sns-post__content" style={{ whiteSpace: "pre-wrap" }}>
          {value.content}
        </div>
        <div className="sns-post__timestamp">
          {format(new Date(value.createdAt), "yyyy-MM-dd HH:mm")}
        </div>
        <button className="sns-post__comment-icon flex-row align-items-center">
          <Icon>chat_bubble</Icon>
          <p>{num_comments}</p>
        </button>
      </div>
    </div>
  );
};

export const SNSPost = ({
  value,
  full,
  onClick,
}: {
  value: any;
  full: boolean;
  onClick?: (arg0: any) => void;
}) => {
  if (full) {
    return (
      <div className="sns-post--detail">
        <PostContent value={value} />
      </div>
    );
  } else {
    return (
      <div className="sns-post--preview">
        <PostContent onClick={onClick} value={value} />
      </div>
    );
  }
};
