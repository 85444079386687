import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

export const ResendSignUpPage = () => {
  const history = useHistory();
  const resendVerificationCode = (e) => {
    e.preventDefault();
    let username = e.target.username.value;
    Auth.resendSignUp(username)
      .then((resp) => {
        if (resp === "SUCCESS") {
          history.push("/login/confirm");
        } else {
          console.log(resp);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div className="login-screen flex-center">
      <form onSubmit={(e) => resendVerificationCode(e)}>
        <div className="login-screen__field">
          <label>ユーザー名</label>
          <input name="username" />
        </div>
        <button className="primary big-button" type="submit">
          確認コード送信
        </button>
      </form>
    </div>
  );
};
