import { UsersContext } from "../../contexts/UsersContext";
import React, { useState, useContext } from "react";
import { keysForListing, jpLocale } from "../../constants/Uservalues";
import { Icon } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export const UserListPage = () => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const { users, images, getUsername, getDisplayName } =
    useContext(UsersContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const history = useHistory();
  const [usersResult, setUsersResult] = useState(users);
  const handleSearchChange = (e) => {
    // Search with `username` / `displayname` / `lastname` / `firstname`
    let searchValues = e.target.value;
    if (searchValues) {
      let result = users.filter(
        (user) =>
          user.displayname.startsWith(searchValues) ||
          user.username.startsWith(searchValues)
      );
      setUsersResult(result);
    } else {
      setUsersResult(users);
    }
  };
  return (
    <>
      <div
        className="modal--bg"
        style={{
          display: showModal ? "" : "none",
        }}
      >
        <div className="modal--button-container">
          <button
            className="float-button bg-white"
            style={{
              marginBottom: 20,
            }}
            onClick={() => history.push("/users/new")}
          >
            <Icon>add</Icon>
            <p>作成</p>
          </button>
          <button
            onClick={() => {
              setEditMode(!editMode);
              setShowModal(false);
            }}
            className="float-button bg-white"
            style={{
              marginBottom: 20,
            }}
          >
            <Icon>edit</Icon>
            <div>{editMode ? "閲覧" : "編集"} </div>
          </button>
          <button
            className="float-button bg-orange"
            onClick={() => setShowModal(!showModal)}
          >
            {showModal ? <Icon>close</Icon> : <Icon>add</Icon>}
          </button>
        </div>
      </div>
      <div className="user-list flex-column flex-center w-full">
        <div className="user-list__buttons ">
          <button
            className="float-button bg-orange"
            onClick={() => setShowModal(!showModal)}
          >
            <Icon>add</Icon>
          </button>
        </div>
        <div className="user-list__search flex-center flex-row">
          <input placeholder="検索する" onChange={handleSearchChange} />
        </div>
        <div>{editMode ? "編集" : "閲覧"}モード</div>
        <div className="user-list__container">
          {usersResult.map((user) => (
            <div
              className="user-list-item flex-row"
              onClick={() =>
                history.push(`/users/${(editMode ? "edit/" : "") + user.id}`)
              }
            >
              <div className="user-list-item__image">
                <img src={images[user.id]} />
              </div>
              <div className="flex-column">
                <div className="user-list-item__name flex-row">
                  <div className="user-list-item__label">表示名</div>
                  <div>{getDisplayName(user.id)}</div>
                </div>
                <div className="user-list-item__name flex-row">
                  <div className="user-list-item__label">ユーザー名</div>
                  <div style={{ overflow: "hidden", width: 130, height: 20 }}>
                    {getUsername(user.id) || ""}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="user-list__back-button flex-row flex-center">
          <button className="primary big-button" onClick={history.goBack}>
            戻る
          </button>
        </div>
      </div>
    </>
  );
};

export const UserListPageOld = (props) => {
  const { users, images } = useContext(UsersContext);

  const history = useHistory();
  let keys: string[] = [];
  if (users.length > 0) {
    keys = Object.keys(users[0]);
  }
  console.log(users);

  return (
    <>
      <table className="w-full">
        <thead>
          <tr className="bg-gray-100">
            {keys.map((key, idx) => {
              if (keysForListing.includes(key)) {
                return <th key={idx}>{jpLocale[key]}</th>;
              } else {
                return <React.Fragment key={idx} />;
              }
            })}
          </tr>
        </thead>
        {users.map((user, idx) => (
          <tbody key={idx}>
            <tr
              className="h-10 border-b hover:bg-gray-200"
              key={idx}
              onClick={(e) => history.push("/users/edit/" + user.id)}
            >
              {keys.map((key, idx) => {
                if (keysForListing.includes(key)) {
                  if (key === "image") {
                    return (
                      <td className="border">
                        <img
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          className="w-12 h-12 object-cover rounded-full shadow"
                          src={images[user.username]}
                          alt="profile-mini"
                        />
                      </td>
                    );
                  }
                  return (
                    <td key={idx} className="text-xs p-1 border">
                      {user[key]}
                    </td>
                  );
                } else {
                  return <React.Fragment key={idx} />;
                }
              })}
            </tr>
          </tbody>
        ))}
      </table>
    </>
  );
};
