import { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { inputStyle } from "../ClassNames";
import { Alert } from "@material-ui/lab";

export const ForgotPasswordPage = () => {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState<boolean>(true);
  const [failed, setFailed] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("エラーが発生しました。");
  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (checkPassword) {
      Auth.forgotPasswordSubmit(username, code, newPassword)
        .then(() => {
          setFailed(false);
          history.push("/login");
        })
        .catch((e) => {
          console.error(e);
          setErrorMessage("エラーが発生しました。");
          setFailed(true);
        });
    } else {
      setErrorMessage("パスワードが一致していません。");
      setFailed(true);
    }
  };

  const handleSendCode = () => {
    Auth.forgotPassword(username)
      .then((data) => {
        console.log(data);
        setCodeSent(true);
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
        setCodeSent(false);
        if (err.code === "LimitExceededException") {
          setErrorMessage(
            "試行回数が多すぎます。時間をおいてから試してください。"
          );
        }
      });
  };

  return (
    <div className="login-screen flex-center">
      {failed && <Alert severity="error">{errorMessage}</Alert>}
      <div className="login-screen__field">
        <label>ユーザー名</label>
        <input
          className={inputStyle.base}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      {codeSent && (
        <>
          <div className="login-screen__field">
            <label>確認コード</label>
            <input
              className={inputStyle.base}
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          <div className="login-screen__field">
            <label>新しいパスワード</label>
            <input
              className={inputStyle.base}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              type="password"
            />
          </div>
          <div className="login-screen__field">
            <label>新しいパスワードの確認</label>
            <input
              className={inputStyle.base}
              onChange={(e) => setCheckPassword(e.target.value === newPassword)}
              type="password"
            />
          </div>
        </>
      )}
      {!codeSent && (
        <button className="primary big-button" onClick={handleSendCode}>
          コード送信
        </button>
      )}
      {codeSent && (
        <button
          className="primary big-button"
          type="submit"
          onClick={(e) => handleForgotPassword(e)}
        >
          変更
        </button>
      )}
    </div>
  );
};
