export const keysNotToDisplayAdmin = ["assigned", "leader", "id"];

export const keysForListing = [
  "name",
  "description",
  "location_name",
  "status",
];

export const immutable = [
  "id",
  "status",
  "timeslot",
  "leaderID",
  "assigned",
  "createdAt",
  "updatedAt",
];

export const localeJp = {
  id: "ID",
  owner: "管理者",
  name: "現場名",
  memo: "メモ",
  location_name: "現場位置",
  location_url: "現場URL",
  timeslots: "作業予定日時",
  leader: "責任者",
  leaderID: "責任者ID",
  assigned: "メンバー",
  images: "現場写真",
  description: "特記事項",
  status: "進捗",
  type: "種類",
  IDLING: "作業前",
  WORKING: "作業中",
  DONE: "作業完了",
  createdAt: "作成日時",
  updatedAt: "最終変更",
  WORKER: "メンバー",
  LEADER: "責任者",
  ADMIN: "管理者",
  HOUSE_CONSTRUCTION: "住宅組み立て",
  HOUSE_DESTRUCTION: "住宅解体",
  LARGE_PROPERTY_CONSTRUCTION: "大型物件組み立て",
  LARGE_PROPERTY_DESTRUCTION: "大型物件解体",
};

export const statusList = [
  // { label: "下書き", value: "DRAFT" },
  { label: "作業前", value: "IDLING" },
  { label: "作業中", value: "WORKING" },
  { label: "作業完了", value: "DONE" },
  // { label: "アーカイブ", value: "ARCHIVE" },
];

export const typeList = [
  // { label: "下書き", value: "DRAFT" },
  { label: "住宅組み立て", value: "HOUSE_CONSTRUCTION", color: 'orange' },
  { label: "住宅解体", value: "HOUSE_DESTRUCTION", color: 'red' },
  { label: "大型物件組み立て", value: "LARGE_PROPERTY_CONSTRUCTION", color: 'green' },
  { label: "大型物件解体", value: "LARGE_PROPERTY_DESTRUCTION", color: 'blue' },
  // { label: "アーカイブ", value: "ARCHIVE" },
];

export const typeColorMap = {
  HOUSE_CONSTRUCTION: "orange",
  HOUSE_DESTRUCTION: "red",
  LARGE_PROPERTY_CONSTRUCTION: "green",
  LARGE_PROPERTY_DESTRUCTION: "blue",
}
