/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      displayname
      firstname
      lastname
      phoneNumber
      email
      type
      image
      tasks {
        items {
          id
          taskID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          userID
          type
          content
          read
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      displayname
      firstname
      lastname
      phoneNumber
      email
      type
      image
      tasks {
        items {
          id
          taskID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          userID
          type
          content
          read
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      displayname
      firstname
      lastname
      phoneNumber
      email
      type
      image
      tasks {
        items {
          id
          taskID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          userID
          type
          content
          read
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        username
        displayname
        firstname
        lastname
        phoneNumber
        email
        type
        image
        tasks {
          nextToken
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
      type
      content
      read
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        username
        displayname
        firstname
        lastname
        phoneNumber
        email
        type
        image
        tasks {
          nextToken
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
      type
      content
      read
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        username
        displayname
        firstname
        lastname
        phoneNumber
        email
        type
        image
        tasks {
          nextToken
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
      type
      content
      read
      createdAt
      updatedAt
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      name
      description
      memo
      location_name
      location_url
      status
      timeslots
      leaderID
      leader {
        id
        username
        displayname
        firstname
        lastname
        phoneNumber
        email
        type
        image
        tasks {
          nextToken
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
      assigned {
        items {
          id
          taskID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      images
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      name
      description
      memo
      location_name
      location_url
      status
      timeslots
      leaderID
      leader {
        id
        username
        displayname
        firstname
        lastname
        phoneNumber
        email
        type
        image
        tasks {
          nextToken
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
      assigned {
        items {
          id
          taskID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      images
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      name
      description
      memo
      location_name
      location_url
      status
      timeslots
      leaderID
      leader {
        id
        username
        displayname
        firstname
        lastname
        phoneNumber
        email
        type
        image
        tasks {
          nextToken
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
      assigned {
        items {
          id
          taskID
          userID
          createdAt
          updatedAt
        }
        nextToken
      }
      images
      type
      createdAt
      updatedAt
    }
  }
`;
export const createUserTask = /* GraphQL */ `
  mutation CreateUserTask(
    $input: CreateUserTaskInput!
    $condition: ModelUserTaskConditionInput
  ) {
    createUserTask(input: $input, condition: $condition) {
      id
      taskID
      userID
      task {
        id
        name
        description
        memo
        location_name
        location_url
        status
        timeslots
        leaderID
        leader {
          id
          username
          displayname
          firstname
          lastname
          phoneNumber
          email
          type
          image
          createdAt
          updatedAt
        }
        assigned {
          nextToken
        }
        images
        type
        createdAt
        updatedAt
      }
      user {
        id
        username
        displayname
        firstname
        lastname
        phoneNumber
        email
        type
        image
        tasks {
          nextToken
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserTask = /* GraphQL */ `
  mutation UpdateUserTask(
    $input: UpdateUserTaskInput!
    $condition: ModelUserTaskConditionInput
  ) {
    updateUserTask(input: $input, condition: $condition) {
      id
      taskID
      userID
      task {
        id
        name
        description
        memo
        location_name
        location_url
        status
        timeslots
        leaderID
        leader {
          id
          username
          displayname
          firstname
          lastname
          phoneNumber
          email
          type
          image
          createdAt
          updatedAt
        }
        assigned {
          nextToken
        }
        images
        type
        createdAt
        updatedAt
      }
      user {
        id
        username
        displayname
        firstname
        lastname
        phoneNumber
        email
        type
        image
        tasks {
          nextToken
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserTask = /* GraphQL */ `
  mutation DeleteUserTask(
    $input: DeleteUserTaskInput!
    $condition: ModelUserTaskConditionInput
  ) {
    deleteUserTask(input: $input, condition: $condition) {
      id
      taskID
      userID
      task {
        id
        name
        description
        memo
        location_name
        location_url
        status
        timeslots
        leaderID
        leader {
          id
          username
          displayname
          firstname
          lastname
          phoneNumber
          email
          type
          image
          createdAt
          updatedAt
        }
        assigned {
          nextToken
        }
        images
        type
        createdAt
        updatedAt
      }
      user {
        id
        username
        displayname
        firstname
        lastname
        phoneNumber
        email
        type
        image
        tasks {
          nextToken
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      content
      comments {
        items {
          id
          content
          postID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      images
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      content
      comments {
        items {
          id
          content
          postID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      images
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      content
      comments {
        items {
          id
          content
          postID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      images
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      content
      postID
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      content
      postID
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      content
      postID
      owner
      createdAt
      updatedAt
    }
  }
`;
