import { useState, createContext, useContext } from "react";
import { AmplifyAuthContext } from "./AmplifyAuthContext";

export interface MenuItem {
  name: string;
  link: string;
  icon: string;
  onClick?: () => void | null;
}

export interface IUIContext {
  showHamburger: boolean;
  setShowHamburger: (arg0: boolean) => void;
  items: MenuItem[];
}

export const UIContext = createContext<IUIContext>({
  showHamburger: false,
  setShowHamburger: (_arg0: boolean) => {},
  items: [],
});

export const UIStateProvider = ({ children }: { children: JSX.Element }) => {
  const [showHamburger, setShowHamburger] = useState<boolean>(false);
  const { authenticated, adminGroup, setShowLogoutModal } =
    useContext(AmplifyAuthContext);

  let items: MenuItem[] = [];
  if (authenticated) {
    if (adminGroup) {
      items = [
        { name: "カレンダー", link: "/calendar", icon: "event" },
        { name: "社内SNS", link: "/sns", icon: "forum" },
        { name: "案件一覧", link: "/tasks", icon: "list_alt" },
        {
          name: "ユーザー一覧",
          link: "/users/edit",
          icon: "people",
        },
        { name: "アカウント", link: "/profile", icon: "admin_panel_settings" },
        {
          name: "ログアウト",
          link: "/logout",
          icon: "exit_to_app",

          onClick: () => setShowLogoutModal(true),
        },
      ];
    } else {
      items = [
        // { name: "通知", link: "/notifications", icon: "notifications" },
        { name: "カレンダー", link: "/calendar", icon: "event" },
        { name: "社内SNS", link: "/sns", icon: "forum" },
        { name: "案件", link: "/tasks", icon: "list_alt" },
        { name: "アカウント", link: "/profile", icon: "admin_panel_settings" },
        {
          name: "ログアウト",
          link: "/logout",
          icon: "exit_to_app",
          onClick: () => setShowLogoutModal(true),
        },
      ];
    }
  } else {
    items = [
      { name: "ログイン", link: "/login", icon: "lock" },
      { name: "メール認証", link: "/login/confirm", icon: "email" },
      { name: "認証コード再送", link: "/login/resend", icon: "email" },
      {
        name: "パスワードを忘れた方へ",
        link: "/forgot_password",
        icon: "help",
      },
    ];
  }

  return (
    <UIContext.Provider
      value={{
        showHamburger,
        setShowHamburger,
        items: items,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
