import { useContext, useState } from "react";
import { SNSContext } from "../../contexts";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Button } from "../../atoms";

export const SNSNewPage = () => {
  const [content, setContent] = useState("");
  const { addPost, refresh } = useContext(SNSContext);
  const history = useHistory();

  const handlePost = () => {
    Auth.currentUserInfo().then(() => {
      addPost({
        input: {
          content: content,
        },
      })
        .then((resp) => {
          console.log(resp);
          refresh();
        })
        .catch((err) => {
          console.log(err);
        });
    });
    history.push("/sns");
  };

  const handleBack = () => {
    history.push("/sns");
  };
  return (
    <div className="col-wrapper p-5">
      <textarea
        className="sns-post--new"
        value={content}
        placeholder="140文字以内で記入してください。"
        onChange={(e) => setContent(e.target.value)}
      />
      <div style={{ height: 66 }}></div>
      <Button onClick={() => handlePost()}>投稿する</Button>
      <div style={{ height: 40 }}></div>
      <Button onClick={handleBack}>戻る</Button>
    </div>
  );
};
