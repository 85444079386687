import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navbar } from "./organisms/Navbar";

import { Providers } from "./contexts";
import { PrivateRoute, AdminRoute } from "./organisms/PrivateRoute";
import { routes, Permission } from "./constants/routing";
import { Toaster } from "react-hot-toast";

const App = () => {
  return (
    <>
      <Router>
        <Providers>
          <>
            <Navbar />
            <Toaster />
            <div style={{ paddingTop: 56 }}>
              <Switch>
                {routes.map((route) => {
                  if (route.permission === Permission.ADMIN) {
                    return (
                      <AdminRoute
                        path={route.path}
                        component={route.component}
                        exact
                      />
                    );
                  } else if (route.permission === Permission.AUTHORIZED) {
                    return (
                      <PrivateRoute
                        path={route.path}
                        component={route.component}
                        exact
                      />
                    );
                  } else {
                    return (
                      <Route
                        path={route.path}
                        component={route.component}
                        exact
                      />
                    );
                  }
                })}
              </Switch>
            </div>
          </>
        </Providers>
      </Router>
    </>
  );
};

export default App;
