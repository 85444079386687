import { useState, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { ButtonsModal } from "../../organisms/ButtonsModal";
import { Icon } from "@material-ui/core";
import { Task } from "../../contexts/TasksContext";
import {
  NotificationsContext,
  AmplifyAuthContext,
  TasksContext,
  UsersContext,
} from "../../contexts";
import { format } from "date-fns";

const TaskListItem = ({ task }: any) => {
  const history = useHistory();
  const { getDisplayName } = useContext(UsersContext);
  return (
    <div
      className="task-list-item flex-col"
      onClick={() => history.push(`/tasks/${task.id}`)}
      key={task.id}
      style={{ minWidth: 300, maxWidth: 340 }}
    >
      <div className="flex-row">
        <div className="task-list-item__title">
          {`${task?.start?.getHours()}`.padStart(2, "0")}:
          {`${task?.start?.getMinutes()}`.padStart(2, "0")}
        </div>
        <div className="task-list-item__value flex-row">
          <div
            className={`circle bg--${task?.type?.toLowerCase() || "default"} `}
          />
          <div className="ml-2 w-full overflow-hidden h-7">{task?.title}</div>
        </div>
      </div>
      <div className="flex-row">
        <div className="task-list-item__title">
          {`${task?.end?.getHours()}`.padStart(2, "0")}:
          {`${task?.end?.getMinutes()}`.padStart(2, "0")}
        </div>
        <div className="task-list-item__value flex-row">
          <Icon>event</Icon>
          <div className="ml-2">{`${format(task.start, "yyyy/MM/dd")}~${format(
            task.end,
            "yyyy/MM/dd"
          )}`}</div>
        </div>
      </div>
      <div className="task-list-item__value flex-row">
        <div className="task-list-item__title">責任者</div>
        <div className="task-list-item__value">
          <div
            className={`nametag--small bg--${
              task?.type?.toLowerCase() || "default"
            }`}
          >
            {task?.leadername}
          </div>
        </div>
      </div>
      <div className="task-list-item__value flex-row">
        <div className="task-list-item__title">同行者</div>
        <div className="task-list-item__value flex-row flex-wrap">
          {(task?.members || []).map((member: string) => {
            return (
              <div
                key={member}
                className={`nametag--small bg--${
                  task?.type?.toLowerCase() || "default"
                } mr-2`}
              >
                {getDisplayName(member)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const TasksCalendarPage = () => {
  const history = useHistory();
  const { byTimeslot, withAssigned, removeTask } = useContext(TasksContext);
  const { removeNotificationOfTask } = useContext(NotificationsContext);
  const { adminGroup } = useContext(AmplifyAuthContext);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteMode, setDeleteMode] = useState<boolean>(false);
  const [showEndBeforeToday, setShowEndBeforeToday] = useState<boolean>(false);
  const pageSize = 10;
  const handleAdd = () => {
    setShowModal(false);
    history.push("/tasks/new");
  };
  const toggleRemoveMode = () => {
    setShowModal(false);
    setDeleteMode(!deleteMode);
  };

  const itemsToShow = useMemo(() => {
    console.log(
      byTimeslot
        .sort((a, b) => b.start.getTime() - a.start.getTime())
        .map((item) => item.start.getTime())
    );
    return byTimeslot.filter((item) =>
      showEndBeforeToday ? true : item.end > new Date()
    );
  }, [showEndBeforeToday, byTimeslot]);

  const handleDelete = (task) => {
    let user_response = window.confirm(
      `${task.title}を本当に削除してよろしいですか？`
    );
    if (user_response) {
      removeNotificationOfTask(task.id);
      removeTask(task.id)
        .then(() => {
          console.log("REMOVED");
        })
        .catch((e) => {
          console.error(e);
          console.error("FAILED TO REMOVE");
        });
    }
  };
  return (
    <>
      <ButtonsModal
        show={showModal}
        setShow={setShowModal}
        handleAdd={handleAdd}
        handleRemove={toggleRemoveMode}
      />
      <div className="flex-col flex-center w-full" style={{ marginTop: 12 }}>
        <div className="flex-row flex-center">
          <input
            id="showendbeforetoday"
            type="checkbox"
            checked={showEndBeforeToday}
            onChange={() => setShowEndBeforeToday(!showEndBeforeToday)}
          />
          <label>今日以前に終了したものも表示する</label>
        </div>
        {itemsToShow
          .sort((a, b) => b.start - a.start)
          .slice(
            currentPage * 10,
            Math.min((currentPage + 1) * 10, itemsToShow.length)
          )
          .map((item: Task) => (
            <div className="flex-row flex-center" key={item.id}>
              <TaskListItem
                task={{ ...item, members: withAssigned[item.id] }}
                key={item.id}
              />
              {deleteMode ? (
                <Icon onClick={() => handleDelete(item)}>delete</Icon>
              ) : (
                <></>
              )}
            </div>
          ))}
        <div className="task-list flex-row flex-center">
          <button onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))}>
            <Icon>chevron_left</Icon>
          </button>
          {Array.from(
            Array(Math.ceil(itemsToShow.length / pageSize)).keys()
          ).map((pageNum) => (
            <button
              key={pageNum}
              className={`
                task-list-pagination 
                ${
                  currentPage === pageNum
                    ? "task-list-pagination--active"
                    : "task-list-pagination--inactive"
                }`}
              onClick={() => setCurrentPage(pageNum)}
            >
              {pageNum + 1}
            </button>
          ))}
          <button
            onClick={() =>
              setCurrentPage(
                Math.min(currentPage + 1, Math.floor(itemsToShow.length / 10))
              )
            }
          >
            <Icon>chevron_right</Icon>
          </button>
        </div>
        <div className="w-full flex-row flex-center" onClick={history.goBack}>
          <button className="primary big-button mb-4">戻る</button>
        </div>
      </div>
      {adminGroup && (
        <div style={{ position: "fixed", right: 15, bottom: 136 }}>
          <button
            className="float-button bg-orange"
            onClick={() => setShowModal(!showModal)}
          >
            {showModal ? <Icon>close</Icon> : <Icon>add</Icon>}
          </button>
        </div>
      )}
    </>
  );
};
