import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { Alert } from "@material-ui/lab";
import { Icon } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Select } from "../../organisms/Select";
import { typeList } from "../../constants/Taskvalues";
import { SelectItem, initialSelectItem } from "./components";
import { TasksContext, UsersContext } from "../../contexts";
import { IUser } from "../../contexts/UsersContext";
import { Task } from "../../contexts/TasksContext";
import { format } from "date-fns";

import { Storage } from "aws-amplify";
import { resizeFile } from "../../molecules";
import { deleteUserTask } from "../../graphql/mutations";

export const AdminTaskEditPage = (props) => {
  const { id } = useParams<{ id: string }>();
  const [changes, setChanges] = useState<
    Partial<
      Task & {
        start: Date;
        end: Date;
        leader: any;
        assigned: any;
        taskType: any;
      }
    >
  >({});
  const { addUserTask, retrieveTask, editTask } = useContext(TasksContext);
  const { getDisplayName } = useContext(UsersContext);

  const [images, setImages] = useState<any[]>([]);
  const [imagesShow, setImagesShow] = useState<any[]>([]);
  const [success, setSuccess] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const { users } = useContext(UsersContext);
  const history = useHistory();
  /*
  const filtered = users.filter((user) => ["WORKER"].includes(user.type));
  const usersToSelect = filtered.map((element) => {
    return { label: element.displayname, value: element.id };
  });*/

  const [leader, setLeader] = useState<SelectItem[]>([initialSelectItem]);
  const [assigned, setAssigned] = useState<SelectItem[]>([]);

  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  const [taskItem, setTaskItem] = useState<Task | null>(null);
  const [originalUserTasks, setOriginalUserTasks] = useState<any[]>([]);

  const updateItem = (key: string) => (value: any) => {
    setChanges({ ...changes, [key]: value });
  };

  useEffect(() => {
    retrieveTask(id).then((task: Task) => {
      let timeslots = JSON.parse(task.timeslots);
      setStart(new Date(timeslots[0].startAt));
      setEnd(new Date(timeslots[0].endAt));

      setLeader([
        { label: getDisplayName(task.leaderID), value: task.leaderID },
      ]);

      let usertasks: { id: string; userID: string; taskID: string }[] =
        task?.assigned?.items;

      setAssigned(
        usertasks.map((usertask) => ({
          value: usertask.userID,
          label: getDisplayName(usertask.userID),
        }))
      );
      setOriginalUserTasks(usertasks);

      let imgs_json = JSON.parse(String(task.images));
      let imagesPromise = imgs_json.map((imgname: string) =>
        Storage.get(imgname)
      );
      Promise.all(imagesPromise).then((images) => setImagesShow(images));
      setTaskItem(task);
    });
  }, []);

  const findSelectItemByValue = (options: any, value) => {
    return [options.find((item) => item.value === value)];
  };

  const handleSave = () => {
    let allPromises = [];
    console.log(changes);
    let requestBody: Partial<
      Task & {
        start: Date;
        end: Date;
        leader: any;
        assigned: any;
        taskType: any;
      }
    > = { ...changes };
    if (changes?.start && changes?.end) {
      requestBody.timeslots = JSON.stringify({
        0: { startAt: start.getTime(), endAt: end.getTime() },
      });
      delete requestBody.start;
      delete requestBody.end;
    }
    if (changes?.leader) {
      requestBody.leaderID = changes.leader.value;
      delete requestBody.leader;
    }
    if (changes?.taskType) {
      requestBody.type = changes.taskType.value;
      delete requestBody.taskType;
    }
    if (changes?.assigned) {
      console.log(changes.assigned);
      let newUserIds = changes.assigned.map((item) => item.value);
      // createUserTasks with this
      let newlyAdded = newUserIds.filter(
        (id: string) =>
          !originalUserTasks.some((userTask) => userTask.userID === id)
      );
      let deleted = originalUserTasks
        .filter((userTask) => !newUserIds.includes(userTask.userID))
        .map((userTask) => userTask.id);
      // THis is an another stuff
      // Get the original users, get diff
      allPromises.push(
        newlyAdded.map((userId: string) =>
          addUserTask({
            input: {
              userID: userId,
              taskID: id,
            },
          })
        )
      );
      allPromises.push(
        ...deleted.map((id: string) =>
          API.graphql(graphqlOperation(deleteUserTask, { input: { id: id } }))
        )
      );
      // delete and add
      delete requestBody.assigned;
    }
    let images_json = JSON.stringify(images);

    allPromises.push(
      editTask({
        input: {
          ...requestBody,
          id: id,
          images: images_json,
        },
      })
    );
    Promise.all(allPromises)
      .then((resp) => {
        console.log(resp);
        setHasError(false);
        setSuccess(true);
      })
      .catch((err) => {
        console.error(err);
        setSuccess(false);
        setHasError(true);
      });
  };

  const handleDelete = (idx: number) => {
    // let image = images[idx];
    // Storage.remove(image.name);
    setImages([...images.slice(0, idx), ...images.slice(idx + 1)]);
    setImagesShow([...imagesShow.slice(0, idx), ...imagesShow.slice(idx + 1)]);
  };

  const handleImageChange = async (e: any) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    // change file.name to something unique
    Storage.put(file.name, image, {
      contentType: "image/jpg",
    })
      .then((result: any) => {
        Storage.get(result.key)
          .then((result) => {
            setImages([...images, file.name]);
            setImagesShow([...imagesShow, result]);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      {taskItem && (
        <div className="new-task flex-column flex-center">
          {hasError && <Alert severity="error">エラーが発生しました。</Alert>}
          {success && <Alert severity="success">タスク編集完了！</Alert>}
          <div className="new-task__location flex-column">
            <div className="flex-row align-items-center">
              <Icon>business</Icon>
              <p>現場名</p>
            </div>
            <input
              value={changes?.name ?? taskItem.name}
              onChange={(e) => setChanges({ ...changes, name: e.target.value })}
            />
          </div>
          <div className="new-task__type flex-column">
            <div className="flex-row align-items-center">
              <Icon>house</Icon>
              <p>現場種類を選択</p>
            </div>
            <Select
              width="325px"
              options={typeList}
              value={findSelectItemByValue(
                typeList,
                changes?.taskType || taskItem.type
              )}
              onChange={updateItem("taskType")}
            />
          </div>
          <div className="new-task__date">
            <div className="flex-row align-items-center">
              <Icon>date_range</Icon>
              <p>日付を選択する</p>
            </div>
            <div className="flex-row align-items-center justify-between">
              <p>START</p>
              <input
                type="date"
                value={format(changes?.start ?? start, "yyyy-MM-dd")}
                onChange={(e) => {
                  let year = e.target.value.split("-")[0];
                  let month = e.target.value.split("-")[1];
                  let date = e.target.value.split("-")[2];
                  let tmp = new Date(end.getTime());
                  tmp.setFullYear(parseInt(year));
                  tmp.setMonth(parseInt(month) - 1);
                  tmp.setDate(parseInt(date));
                  setChanges({ ...changes, start: tmp });
                }}
              />
            </div>
            <div className="flex-row align-items-center justify-between">
              <p>END</p>
              <input
                type="date"
                value={format(changes?.end ?? end, "yyyy-MM-dd")}
                onChange={(e) => {
                  let year = e.target.value.split("-")[0];
                  let month = e.target.value.split("-")[1];
                  let date = e.target.value.split("-")[2];
                  let tmp = new Date(end.getTime());
                  tmp.setFullYear(parseInt(year));
                  tmp.setMonth(parseInt(month) - 1);
                  tmp.setDate(parseInt(date));
                  setChanges({ ...changes, end: tmp });
                }}
              />
            </div>
          </div>
          <div className="new-task__time">
            <div className="flex-row align-items-center">
              <Icon>access_time</Icon>
              <p>時間を選択する</p>
            </div>
            <div className="flex-column">
              <div className="flex-row align-items-center justify-between">
                <p>IN</p>
                <input
                  type="time"
                  step="900"
                  value={format(changes?.start ?? start, "HH:mm")}
                  onChange={(e) => {
                    let hours = e.target.value.split(":")[0];
                    let minutes = e.target.value.split(":")[1];
                    let tmp = new Date(end.getTime());
                    tmp.setHours(parseInt(hours));
                    tmp.setMinutes(parseInt(minutes));
                    setChanges({ ...changes, start: tmp });
                  }}
                />
              </div>
              <div className="flex-row align-items-center justify-between">
                <p>OUT</p>
                <input
                  type="time"
                  step="900"
                  value={format(changes?.end ?? end, "HH:mm")}
                  onChange={(e) => {
                    let hours = e.target.value.split(":")[0];
                    let minutes = e.target.value.split(":")[1];
                    let tmp = new Date(end.getTime());
                    tmp.setHours(parseInt(hours));
                    tmp.setMinutes(parseInt(minutes));
                    setChanges({ ...changes, end: tmp });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="new-task__address">
            <div className="flex-row align-items-center">
              <Icon>room</Icon>
              <p>住所</p>
            </div>
            <input
              value={changes?.location_name ?? taskItem.location_name}
              onChange={(e) =>
                setChanges({ ...changes, location_name: e.target.value })
              }
            />
          </div>
          <div className="new-task__leader">
            <div className="flex-row align-items-center">
              <Icon>person</Icon>
              <p>リーダを選択する</p>
            </div>
            <Select
              width="325px"
              options={[
                ...users
                  .filter((user: IUser) =>
                    ["LEADER", "ADMIN"].includes(user.type)
                  )
                  .map((user: IUser) => ({
                    label: user.displayname,
                    value: user.id,
                  })),
              ]}
              value={changes?.leader ?? leader}
              onChange={updateItem("leader")}
            />
          </div>
          <div className="new-task__members">
            <div className="flex-row align-items-center">
              <Icon>group</Icon>
              <p>メンバーを選択する</p>
            </div>
            <Select
              width="325px"
              options={users
                .filter((user: IUser) => user.type === "WORKER")
                .map((user: IUser) => ({
                  label: user.displayname,
                  value: user.id,
                }))}
              isMulti
              value={changes?.assigned ?? assigned}
              onChange={updateItem("assigned")}
            />
          </div>
          <div className="new-task__images">
            <input type="file" onChange={handleImageChange} />
            <div>
              {imagesShow.map((image, idx) => (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <button
                    onClick={() => handleDelete(idx)}
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: "50%",
                      backgroundColor: "red",
                      color: "white",
                      fontWeight: 900,
                      position: "absolute",
                    }}
                  >
                    ✕
                  </button>
                  <img src={image} alt={`${idx}`} />
                </div>
              ))}
            </div>
          </div>
          <div className="new-task__memo">
            <div className="flex-row align-items-center">
              <Icon>note</Icon>
              <p>メモ（暗証番号など）</p>
            </div>
            <textarea
              value={changes?.memo ?? taskItem.memo}
              onChange={(e) => setChanges({ ...changes, memo: e.target.value })}
            />
          </div>
          <div className="flex-column flex-center w-full">
            <button
              className="big-button primary"
              style={{ marginTop: 30, marginBottom: 16 }}
              onClick={handleSave}
            >
              保存する
            </button>
            <button
              className="big-button secondary"
              onClick={() => history.goBack()}
            >
              戻る
            </button>
          </div>
        </div>
      )}
    </>
  );
};
