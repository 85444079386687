import { useEffect, useContext } from "react";
import { AmplifyAuthContext } from "../contexts/AmplifyAuthContext";
import { useHistory } from "react-router-dom";

export const LogoutPage = () => {
  const history = useHistory();
  const { logout } = useContext(AmplifyAuthContext);
  useEffect(() => {
    logout();
    history.push("/login");
  }, [history, logout]);
  return <></>;
};
