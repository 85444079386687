import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AmplifyAuthContext } from "../contexts/AmplifyAuthContext";
import { Auth } from "aws-amplify";

export const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = (props) => {
  const { authenticated } = React.useContext(AmplifyAuthContext);

  Auth.currentSession()
    .then((resp) => {
      return (
        <Route
          path={props.path}
          exact={props.exact}
          component={props.component}
        />
      );
    })
    .catch((error) => {
      <Redirect to="/login" />;
    });

  return authenticated ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to="/login" />
  );
};

export const AdminRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = (props) => {
  const { adminGroup } = React.useContext(AmplifyAuthContext);

  return adminGroup ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to="/" />
  );
};
