import {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import {
  getPost,
  getComment,
  // listPosts,
  listComments,
} from "../graphql/queries";
import { listPostsWithComments } from "../graphql/my_queries";
import { API, graphqlOperation } from "aws-amplify";
import { AmplifyAuthContext } from "./AmplifyAuthContext";
import { UsersContext } from "./UsersContext";
import {
  createPost,
  createComment,
  updatePost,
  updateComment,
  deletePost,
  deleteComment,
  createNotification,
} from "../graphql/mutations";

interface IComment {
  [key: string]: any;
  owner: string;
}
export interface IPost {
  id: string;
  comments: { items: IComment[] };
  createdAt: number;
  content: string;
  owner: string;
  ownerID?: string;
}

interface gqlMutate {
  input: any;
  condition?: any;
}

export interface ISNSContext {
  comments: any[];
  posts: any[];
  refresh: () => void;
  addPost: (arg0: gqlMutate) => Promise<any>;
  addComment: (arg0: gqlMutate) => Promise<any>;
  editPost: (arg0: gqlMutate) => Promise<any>;
  editComment: (arg0: gqlMutate) => Promise<any>;
  removePost: (arg0: gqlMutate) => Promise<any>;
  removeComment: (arg0: gqlMutate) => Promise<any>;
  retrievePost: (id: string) => Promise<any>;
  retrieveComment: (id: string) => Promise<any>;
}

const placeholderPromiseFunc = (_arg0: any) =>
  new Promise((_resolve, _reject) => {});

export const SNSContext = createContext<ISNSContext>({
  comments: [],
  posts: [],
  refresh: () => {},
  addPost: placeholderPromiseFunc,
  addComment: placeholderPromiseFunc,
  editPost: placeholderPromiseFunc,
  editComment: placeholderPromiseFunc,
  removePost: placeholderPromiseFunc,
  removeComment: placeholderPromiseFunc,
  retrievePost: placeholderPromiseFunc,
  retrieveComment: placeholderPromiseFunc,
});

export const SNSProvider = ({ children }) => {
  const [posts, setPosts] = useState<IPost[]>([]);
  const [comments, setComments] = useState([]);
  const [postOwner, setPostIdOwner] = useState({});
  const { id } = useContext(AmplifyAuthContext);
  const { getID } = useContext(UsersContext);

  const refresh = useCallback(() => {
    (API.graphql(graphqlOperation(listPostsWithComments)) as Promise<any>).then(
      (resp: any) => {
        let respPosts: IPost[] = resp.data.listPosts.items;
        setPosts(respPosts.sort(compareByCreatedAt));
        let tmp = {};
        respPosts.forEach((post) => {
          tmp[post.id] = { ownerID: getID(post.owner) };
        });
        setPostIdOwner(tmp);
      }
    );
    (API.graphql(graphqlOperation(listComments)) as Promise<any>).then(
      (resp: any) => {
        setComments(
          resp.data.listComments.items.sort(compareByCreatedAtReverse)
        );
      }
    );
  }, []);

  const addPost = ({ input, condition = null }) => {
    const addFunc = (resolve, reject) => {
      (
        API.graphql(
          graphqlOperation(createPost, { input: input, condition: condition })
        ) as Promise<any>
      )
        .then((resp: any) => {
          refresh();
          resolve(resp.data.createPost);
        })
        .catch((e) => {
          reject(e);
        });
    };
    return new Promise(addFunc);
  };
  const addComment = ({ input, condition = null }) => {
    const addFunc = (resolve, reject) => {
      (
        API.graphql(
          graphqlOperation(createComment, {
            input: input,
            condition: condition,
          })
        ) as Promise<any>
      )
        .then((resp: any) => {
          let comment = resp.data.createComment;
          API.graphql(
            graphqlOperation(createNotification, {
              input: {
                userID: postOwner[comment.postID].ownerID,
                type: "COMMENT",
                content: comment.postID,
                read: false,
              },
            })
          );
          refresh();
          resolve(resp.data.createComment);
        })
        .catch((e) => {
          reject(e);
        });
    };
    return new Promise(addFunc);
  };

  const retrievePost = async (id: string) => {
    let post: any = await API.graphql(graphqlOperation(getPost, { id: id }));
    return post.data.getPost;
  };

  const removePost = ({ input, condition = null }) => {
    const removeFunc = (resolve, reject) => {
      (
        API.graphql(
          graphqlOperation(deletePost, { input: input, condition: condition })
        ) as Promise<any>
      )
        .then((resp) => {
          // Need to remove the comments that comes along with it
          // Or just keep it in the DB and set as INACTIVE or something
          refresh();
          resolve(resp.data.deletePost);
        })
        .catch((e) => {
          reject(e);
        });
    };

    return new Promise(removeFunc);
  };

  const editPost = ({ input, condition = null }) => {
    const editFunc = (resolve, reject) => {
      (
        API.graphql(
          graphqlOperation(updatePost, { input: input, condition: condition })
        ) as Promise<any>
      )
        .then((resp) => {
          refresh();
          resolve(resp.data.updatePost);
        })
        .catch((e) => {
          reject(e);
        });
    };
    return new Promise(editFunc);
  };

  const retrieveComment = async (id) => {
    let comment: any = await API.graphql(
      graphqlOperation(getComment, { id: id })
    );
    return comment.data.getComment;
  };

  const removeComment = ({ input, condition = null }) => {
    const removeFunc = (resolve, reject) => {
      (
        API.graphql(
          graphqlOperation(deleteComment, {
            input: input,
            condition: condition,
          })
        ) as Promise<any>
      )
        .then((resp) => {
          refresh();
          resolve(resp.data.deleteComment);
        })
        .catch((e) => {
          reject(e);
        });
    };
    return new Promise(removeFunc);
  };

  const editComment = ({ input, condition = null }) => {
    const editFunc = (resolve, reject) => {
      (
        API.graphql(
          graphqlOperation(updateComment, {
            input: input,
            condition: condition,
          })
        ) as Promise<any>
      )
        .then((resp) => {
          refresh();
          resolve(resp.data.updateComment);
        })
        .catch((e) => {
          reject(e);
        });
    };
    return new Promise(editFunc);
  };

  function compareByCreatedAt(a, b) {
    let a_time = new Date(a.createdAt);
    let b_time = new Date(b.createdAt);
    return b_time.getTime() - a_time.getTime();
  }
  function compareByCreatedAtReverse(a, b) {
    let a_time = new Date(a.createdAt);
    let b_time = new Date(b.createdAt);
    return a_time.getTime() - b_time.getTime();
  }
  // Still needs to update Posts / Comments as it goes on, and there should be refresh i guess
  useEffect(() => {
    if (id) {
      refresh();
    }
  }, [id, refresh]);
  return (
    <SNSContext.Provider
      value={{
        comments: comments,
        posts: posts,
        refresh: refresh,
        addPost: addPost,
        addComment: addComment,
        editPost: editPost,
        editComment: editComment,
        retrieveComment: retrieveComment,
        retrievePost: retrievePost,
        removeComment: removeComment,
        removePost: removePost,
      }}
    >
      {children}
    </SNSContext.Provider>
  );
};
