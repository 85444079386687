import { useState, useEffect, createContext } from "react";
import { Auth } from "aws-amplify";

export const AmplifyAuthContext = createContext<any>({
  username: "",
  id: "",
  authenticated: null,
  adminGroup: null,
  showLogoutModal: false,
  setShowLogoutModal: (_arg0: boolean) => {},
  login: () => {},
  logout: () => {},
  changePassword: () => {},
});

export const AmplifyAuthProvider = (props) => {
  const [username, setUsername] = useState(sessionStorage.getItem("username"));
  const [id, setId] = useState(sessionStorage.getItem("id"));
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [adminGroup, setAdminGroup] = useState<boolean>(
    !!sessionStorage.getItem("adminGroup")
  );
  const [authenticated, setAuthenticated] = useState<boolean>(
    !!sessionStorage.getItem("authenticated")
  );

  const updateUserinfo = (userinfo) => {
    try {
      setAdminGroup(
        userinfo.signInUserSession.accessToken.payload[
          "cognito:groups"
        ].includes("ADMIN")
      );
      sessionStorage.setItem(
        "adminGroup",
        userinfo.signInUserSession.accessToken.payload[
          "cognito:groups"
        ].includes("ADMIN")
      );
    } catch (e) {
      console.error(e);
      setAdminGroup(false);
      sessionStorage.setItem("adminGroup", "");
    }

    sessionStorage.setItem("id", userinfo.attributes.sub);
    sessionStorage.setItem("username", userinfo.username);
    setUsername(userinfo.username);
    setId(userinfo.attributes.sub);
    setAuthenticated(userinfo !== undefined);
    sessionStorage.setItem(
      "authenticated",
      userinfo !== undefined ? "" : "true"
    );
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userinfo) => {
        updateUserinfo(userinfo);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const login = (params) => {
    const loginfunc = (resolve, reject) => {
      Auth.signIn({
        username: params.username,
        password: params.password,
      })
        .then((user) => {
          updateUserinfo(user);
          resolve(user);
        })
        .catch((e) => {
          reject(e);
        });
    };
    return new Promise(loginfunc);
  };

  const logout = (params) => {
    Auth.signOut();
    sessionStorage.setItem("id", "");
    sessionStorage.setItem("username", "");
    sessionStorage.setItem("adminGroup", "");
    sessionStorage.setItem("authenticated", "");
    setId("");
    setUsername("");
    setAuthenticated(false);
    setAdminGroup(false);
  };

  const changePassword = (params) => {
    const changeFunc = (resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(
            user,
            params.oldPassword,
            params.newPassword
          );
        })
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    };

    return new Promise(changeFunc);
  };

  return (
    <AmplifyAuthContext.Provider
      value={{
        login: login,
        username: username,
        adminGroup: adminGroup,
        id: id,
        logout: logout,
        showLogoutModal,
        setShowLogoutModal,
        changePassword: changePassword,
        authenticated: authenticated,
      }}
    >
      {props.children}
    </AmplifyAuthContext.Provider>
  );
};
