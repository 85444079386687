export const listPostsWithComments = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        comments {
          items {
            id
          }
        }
        owner
        images
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listUsersWithTasks = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        displayname
        type
        image
        tasks {
          items {
            id
          }
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTasksWithAssigned = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        memo
        location_name
        location_url
        status
        timeslots
        type
        leaderID
        leader {
          id
          username
          displayname
          type
          image
          createdAt
          updatedAt
        }
        assigned {
          items {
            id
            username
            displayname
            type
          }
        }
        images
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
