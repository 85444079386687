import React, { useState } from "react";
import { Icon } from "@material-ui/core";
interface SelectOption {
  label: string;
  value: any;
}

const demoOptions: SelectOption[] = [
  { label: "a", value: "A" },
  { label: "b", value: "B" },
  { label: "c", value: "C" },
  { label: "d", value: "D" },
  { label: "e", value: "E" },
];
export const Select = ({
  options = demoOptions,
  value = [],
  onChange = () => {},
  isMulti = false,
  width = "100%",
}: {
  options?: SelectOption[];
  value?: SelectOption[];
  onChange?: (arg0: any) => void;
  isMulti?: boolean;
  width?: string;
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let selectedTmp: any[] = Array.apply(
      null,
      e.target.options as unknown as any[]
    ).filter((option: any) => option.selected);

    onChange(
      selectedTmp.map((option) => ({
        label: option.label,
        value: option.value,
      }))
    );
  };
  return (
    <div
      className="select"
      onBlur={() => setShowOptions(false)}
      style={{ width, minWidth: width, maxWidth: width }}
    >
      {isMulti && (
        <div
          className="select__input"
          style={{ width, minWidth: width, maxWidth: width }}
          onClick={() => setShowOptions(!showOptions)}
        >
          <div className="flex-row" style={{ flexWrap: "wrap" }}>
            {value.map((item, idx: number) => (
              <div key={idx} className="select__multi-selected">
                {item.label}
              </div>
            ))}
          </div>
          <Icon className="select__icon">
            {showOptions ? "keyboard_arrow_up" : "keyboard_arrow_down"}
          </Icon>
        </div>
      )}
      {((isMulti && showOptions) || !isMulti) && (
        <select
          onBlur={() => setShowOptions(false)}
          className={isMulti ? "select__options" : "select__options-single"}
          style={{ width, minWidth: width, maxWidth: width }}
          multiple={isMulti}
          onChange={handleChange}
        >
          {options.map((option, idx: number) => {
            return (
              <option
                key={idx}
                value={option.value}
                className="select__option"
                style={{ width: "100%" }}
                selected={value?.some((item) => item.value === option.value)}
              >
                {option.label}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
};
