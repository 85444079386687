import { useState, useContext, useEffect } from "react";
import { AmplifyAuthContext, UsersContext } from "../../contexts";
import { IUser } from "../../contexts/UsersContext";
import { useHistory, useLocation, useParams } from "react-router-dom";

export const UserPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [currentId, setCurrentId] = useState<string>("");
  const [targetUser, setTargetUser] = useState<Partial<IUser>>({});
  const { id, adminGroup } = useContext(AmplifyAuthContext);
  const { users, images } = useContext(UsersContext);
  const editable = location.pathname.includes("profile") || adminGroup;
  const { user_id } = useParams<{ user_id: string }>();

  const placeholderImage = "https://i.imgur.com/WCfimk2.jpg";

  useEffect(() => {
    if (location.pathname.includes("profile")) {
      setCurrentId(id);
      setTargetUser({
        ...users.filter((user: IUser) => user.id === id)[0],
        image: images[id] || placeholderImage,
      });
    } else {
      setCurrentId(user_id);
      setTargetUser({
        ...users.filter((user: IUser) => user.id === user_id)[0],
        image: images[user_id] || placeholderImage,
      });
    }
  }, []);

  const handleClickChange = () => {
    if (location.pathname.includes("profile")) {
      history.push("/profile/edit");
    } else {
      history.push(`/users/edit/${currentId}`);
    }
  };

  const TextField = ({ label, value }: { label: string; value: string }) => {
    return (
      <div className="user-detail__field">
        <label>{label}</label>
        <p>{value}</p>
      </div>
    );
  };

  return (
    <div className="user-detail flex-column flex-center">
      <div className="user-detail__image">
        <img src={targetUser.image} />
      </div>
      <div className="user-detail__fields flex-column">
        <TextField label="表示名" value={targetUser.displayname} />
        <TextField label="姓" value={targetUser.lastname} />
        <TextField label="名" value={targetUser.firstname} />
        <TextField label="メールアドレス" value={targetUser.email} />
        <TextField label="電話番号" value={targetUser.phoneNumber} />
      </div>
      <div className="user-detail__buttons flex-column">
        {editable && (
          <button className="big-button primary" onClick={handleClickChange}>
            変更する
          </button>
        )}
        <div style={{ height: 20 }} />
        <button
          className="big-button secondary"
          onClick={() => history.goBack()}
        >
          戻る
        </button>
      </div>
    </div>
  );
};
